import React from "react";
import Select from 'react-select';
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/SelectUser.css';
import { GetData, PostData } from '../../../Javascripts/API'
import Header from '../../layouts/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

class SelectUser extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isAuthenticated: false,
            accounts: {},
            accountUUID: '',
            selectValue: null
        }
        this.handleSelectUser = this.handleSelectUser.bind(this);
    }

    handleSelectUser = async (event) => {
        event.preventDefault();
        if(this.state.selectValue !== null){
            let user = this.state.selectValue;

            await PostData('assess/user/', {uuid: user.value})
            .then((data) => {
                if(data.status === 'success'){
                    this.props.setSelectedUser(data.assessedUser);
                    this.props.history.push('/trajecten');
                }
            });
        }
    }

    handleChange = (option) => {
        this.setState({accountUUID: option.value})
        this.setState({selectValue: option})
    }

    async componentDidMount() {
        await GetData('users')
        .then(response => {
            if(response.status === "success"){
                this.setState({
                    isAuthenticated: true
                });
                this.setState({
                    accounts: response.accounts
                });
            }
            else{
                this.props.history.push('/');
            }
        })
        .catch((err) => {
            this.props.history.push('/');
        })
    }

    render(){
        let accounts = this.state.accounts;
        return (
            <>
                <Header nav={true} data={this.props.data} userData={this.props.userData} history={this.props.history} selectedUser={this.props.selectedUser} setSelectedUser={this.props.setSelectedUser}/>
                <div className="SelectUserContainer slim-container">
                    <h1 className="slim-title" style={{marginBottom: '64px'}}>Gebruiker beoordelen</h1>
                    <form onSubmit={this.handleSelectUser}>
                        <div className="inputBox">
                            <p>Gebruiker<span style={{'color':'red'}}>*</span></p>
                            {this.state.isAuthenticated ?
                                <Select
                                    options={accounts}
                                    onChange={(option) => {this.handleChange(option)}}
                                    value={this.state.selectValue}
                                    placeholder={"Selecteer een gebruiker"}
                                />
                            : <p className="slim-notification">De gebruikers worden opgehaald</p>}
                            <p id="subtext"><FontAwesomeIcon style={{'marginRight': '3px'}} icon={faExclamationCircle}/>Kies hierboven het gebruikersaccount om te beoordelen.</p>
                        </div>
                        <button className={this.state.selectValue === null ? "slim-button disabled" : "slim-button"} style={{'marginTop':'12px'}}>
                            <FontAwesomeIcon id="icon" icon={faClipboardCheck}/>
                            Beoordelen
                        </button>
                    </form>
                </div>
            </>
        );
    }
}
  
export default SelectUser;