import { React, useState, useEffect } from 'react';
import Routes from './Routes';
import { GetData } from '../Javascripts/API'
import Header from './layouts/header';
import Loading from './layouts/Loading';

function App() {

    const [data, setData] = useState(false);
    const [userData, setUserData] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        async function getSettings(){
            await GetData('settings')
            .then(response => {
                let settings = {
                    name: response.Klantnaam,
                    email: response.Emailadres,
                    phone: response.Telefoon,
                    website: response.Website,
                    ontwikkeltrajecten: response.OntwikkeltrajectenUrl,
                    logo: response.Logo,
                }
                setData(settings);
            })
            .catch((err) => {
                console.log(err);
            })
        }

        async function getUserData(){
            await GetData('account')
            .then(response => {
                if(response && response.status === 'success'){
                    setUserData(response.user);
                    if(response.assessUser){
                        setSelectedUser(response.assessUser);
                    }
                    else{
                        setSelectedUser(false);
                    }
                }
                else{
                    setUserData({});
                    setSelectedUser(false);
                }

            })
            .catch((err) => {
                console.log(err);
            })
        }

        getSettings();
        getUserData();
    }, []);

    if(data && userData && selectedUser !== null){
        return (
            <div id="innerContainer">
                <Routes data={ data } userData={ userData } setUserData={ setUserData } selectedUser={ selectedUser } setSelectedUser={ setSelectedUser }/>
            </div>
        )
    }
    else{
        let url = window.location.pathname.split('/');
        let showNav = true;
        let showBackground = false;

        if(url[1] === 'loggedout' || url[2] === 'loggedout' || url[1] === '' || (url[1] === 'cms' && url[1].length === 2)){
            showNav = false;
        }
        if(url[1] === 'dashboard' || url[2] === 'dashboard' || url[1] === '' || (url[1] === 'cms' && url[1].length === 2)){
            showBackground = true;
        }

        return <>
            <Header nav={showNav} data={data}/>
            <h1 className="slim-title" style={{height: '38px'}}> </h1>
            <Loading text={'Bezig met laden...'} size={30}/>{showBackground ? <div className="slim-background"/> : null}</>
    }
}

export default App