import React from "react";
import '../../../Stylesheets/Global.css';
import { GetData } from '../../../Javascripts/API'
import Header from '../../layouts/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe, faPhone, faEnvelope, faGraduationCap } from '@fortawesome/free-solid-svg-icons'

class Contact extends React.Component {

    async componentDidMount() {
        await GetData('auth')
        .then(response => {
            if(response.message !== "Authenticated"){
                this.props.history.push('/');
            }
        })
        .catch((err) => {
            this.props.history.push('/');
        })
    }

    render(){
        let data = this.props.data;
        return (
            <>
                <Header nav={true} data={data} userData={this.props.userData} history={this.props.history} selectedUser={this.props.selectedUser} setSelectedUser={this.props.setSelectedUser}/>
                <div className="slim-container">
                    <h1 className="slim-title" style={{marginBottom: '52px'}}>{`Contactinformatie ${data.name}`}</h1>
                    {data.website.length > 0 || data.phone.length > 0 || data.email.length > 0 ?
                        <ul style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            {data.website.length > 0 ?
                                <li className="slim-button menu-button" onClick={() => {window.open(data.website.includes('https://') ? data.website : `https://${data.website}`)}}>
                                    <FontAwesomeIcon id="icon" icon={faGlobe}></FontAwesomeIcon>{data.website.replace(/https:\/\/|http:\/\//gi, "")}
                                </li> : null
                            }
                            {data.ontwikkeltrajecten.length > 0 ?
                                <li className="slim-button menu-button" onClick={() => {window.open(data.ontwikkeltrajecten.includes('https://') ? data.ontwikkeltrajecten : `https://${data.ontwikkeltrajecten}`)}}>
                                    <FontAwesomeIcon id="icon" icon={faGraduationCap}></FontAwesomeIcon>{data.ontwikkeltrajecten.replace(/https:\/\/|http:\/\//gi, "")}
                                </li> : null
                            }
                            {data.phone.length > 0 ?
                                <li className="slim-button menu-button" onClick={() => {window.location = `tel:${data.phone}`}}>
                                    <FontAwesomeIcon id="icon" icon={faPhone}></FontAwesomeIcon>{data.phone}
                                </li> : null
                            }
                            {data.email.length > 0 ? 
                                <li className="slim-button menu-button" onClick={() => {window.location = `mailto:${data.email}`}}>
                                    <FontAwesomeIcon id="icon" icon={faEnvelope}></FontAwesomeIcon>{data.email}
                                </li> : null
                            }
                        </ul> :
                        <p>Er is voor {data.name ? data.name : 'dit bedrijf'} geen contactinformatie vastgelegd.</p>
                    }
                </div>
            </>
        );
    }
}
  
export default Contact;  