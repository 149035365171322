import { GetData, PostData } from './API'

async function login(username, password, setUserData, history) {
    await PostData('login', {loginUsername: username, loginPassword: password})
    .then((data) => {
        if (data.message === 'Authenticated') {
            setUserData(data.user);
            history.push('/dashboard');
        }
    })
    .catch((err) => {
        throw err;
    })
}

async function loginCMS(username, password, setUserData, history) {
    await PostData('cms/login', {loginUsername: username, loginPassword: password})
    .then((data) => {
        if (data.message === 'Authenticated') {
            setUserData(data.user);
            history.push('/cms/dashboard');
        } else {
            return data.message;
        }
    })
    .catch((err) => {
        throw err;
    })
}

async function logout(history) {

    await GetData('logout')
    .then(() => {
        history.push('/loggedout');
    })
    .catch((err) => {
        console.log(err);
    })
}

async function logoutCMS(history) {

    await GetData('logout')
    .then(() => {
        history.push('/cms/loggedout');
    })
    .catch((err) => {
        console.log(err);
    })
}

export { 
    logout, 
    logoutCMS, 
    login, 
    loginCMS 
}