import React from "react";
import { NavLink } from "react-router-dom";
import { PostData } from '../../Javascripts/API'
import Slide from 'react-reveal/Slide';
import { Squash as Hamburger } from 'hamburger-react'
import '../../Stylesheets/Header.css';
import { logout } from '../../Javascripts/Auth'
import onClickOutside from "react-onclickoutside";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faGraduationCap, faPhone, faSignOutAlt, faClipboardCheck, faUserGraduate, faUser, faEnvelope, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav(){
        this.setState({ displayNav: !this.state.displayNav });
    }

    handleClickOutside(){
        if(this.props.nav && this.state.displayNav){
            this.toggleNav();
        }
    };

    async exitAssessorMode(){
        await PostData('assess/user/logout', {uuid: this.props.selectedUser.UUID})
            .then((data) => {
                if(data.status === 'success'){
                    this.props.setSelectedUser(false);
                    this.props.history.push('/dashboard'); 
                }
                else{
                    this.props.history.push('/');
                }
            });
    }

    state = {
        displayNav: false
    }

    render() {
        return (
            <div className="HeaderContainer">
                <div id="headerBar">
                    {this.props.data.logo !== undefined ?
                        <img src={`/Images/${this.props.data.logo}`} alt={`${this.props.data.name} logo`}></img> : <div style={{height: '55px'}}/>
                    }
                    {this.props.nav ? 
                        <div className="navButtons">
                            <NavLink to="/dashboard" style={{display: 'flex', alignItems: 'center'}}><FontAwesomeIcon id="icon" icon={faHome}></FontAwesomeIcon></NavLink>
                            <Hamburger size={24} rounded color={"var(--main-color)"} id="icon" toggled={this.state.displayNav} toggle={this.toggleNav} />
                        </div> : null   
                    }
                </div>
                <Slide duration={400} top when={this.state.displayNav}>
                <div id="navWindow" className={"noselect"} style={{display: this.state.displayNav ? 'flex' : 'none'}}>
                    {this.props.userData ?
                        <div style={{padding: '16px', fontSize: '12px'}}>
                            <p style={{fontWeight: 'bold'}}>Ingelogd als</p>
                            <p style={{marginTop: '8px'}}>
                                {this.props.userData.accountType === 'Beoordelaar' ?
                                <FontAwesomeIcon id="icon" icon={faUserGraduate}></FontAwesomeIcon> :    
                                <FontAwesomeIcon id="icon" icon={faUser}></FontAwesomeIcon>}
                                {this.props.userData.fullName}
                            </p>
                            <p style={{marginTop: '4px'}}>
                                <FontAwesomeIcon id="icon" icon={faEnvelope}></FontAwesomeIcon>
                                {this.props.userData.email}
                            </p> 
                        </div> : null
                    }
                    {this.props.selectedUser ? 
                        <div style={{padding: '0 0 16px 16px', fontSize: '12px'}}>
                            <p style={{fontWeight: 'bold'}}>Beoordelaarsmodus</p>
                            <p style={{marginTop:'8px'}}>
                                <FontAwesomeIcon id="icon" icon={faUser}></FontAwesomeIcon>
                                {this.props.selectedUser.fullName}
                            </p>
                            <p style={{marginTop:'4px'}}>
                            <FontAwesomeIcon id="icon" icon={faEnvelope}></FontAwesomeIcon>
                                {this.props.selectedUser.email}
                            </p>
                            <div className="slim-button-inline" style={{width:'auto', display:'inline-block', marginTop:'8px'}} onClick={() => this.exitAssessorMode()}>
                                <FontAwesomeIcon id="icon" icon={faArrowLeft}></FontAwesomeIcon>
                                Verlaten
                            </div>
                        </div>
                    : null}
                    <hr/>
                    <NavLink to="/dashboard" onClick={this.toggleNav}>
                        <FontAwesomeIcon id="icon" icon={faHome}></FontAwesomeIcon>
                        Dashboard
                    </NavLink>
                    <NavLink to="/trajecten" onClick={this.toggleNav}>
                        <FontAwesomeIcon id="icon" icon={faGraduationCap}></FontAwesomeIcon>
                        Trajecten
                    </NavLink>
                    {this.props.userData && this.props.userData.accountType === 'Beoordelaar' ?
                        <NavLink to="/selectuser" onClick={this.toggleNav}>
                            <FontAwesomeIcon id="icon" icon={faClipboardCheck}></FontAwesomeIcon>
                            Beoordelen
                        </NavLink> : null
                    }
                    <NavLink to="/contact" onClick={this.toggleNav}>
                        <FontAwesomeIcon id="icon" icon={faPhone}></FontAwesomeIcon>
                        Contact
                    </NavLink>
                    <hr/>
                    <button type="button" onClick={() => logout(this.props.history)}>
                        <FontAwesomeIcon id="icon" icon={faSignOutAlt}></FontAwesomeIcon>
                        Uitloggen
                    </button>
                </div>
                </Slide>
            </div>
        );
    }
}


export default onClickOutside(Header);
