import React from "react";
import '../../../Stylesheets/Global.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import nl from 'date-fns/locale/nl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faCalendarAlt, faExternalLinkAlt, faGraduationCap, faClock, faTimes, faClipboard, faClipboardCheck, faTrash } from '@fortawesome/free-solid-svg-icons'

class QualificationInput extends React.Component {

    constructor(props) {
        super(props); 
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.convertDate = this.convertDate.bind(this);
    }

    state = {
        isDownloading: false,
    }

    convertDate(date){
        let dateObject = new Date(date);

        var day = dateObject.getDate();
        var month = dateObject.getMonth() + 1;
        var year = dateObject.getFullYear();

        return month + "/" + day + "/" + year;
    }

    handleStartDateChange(date){
        let dateToUpdate;

        if(date && date !== ""){
            dateToUpdate = this.convertDate(date);
        }
        else{
            dateToUpdate = ""
        }
        this.props.handleDate('BehaaldDatum', this.props.qualification.UUID_Kwalificatie, dateToUpdate);
    }
    handleEndDateChange(date){
        let dateToUpdate;
        
        if(date && date !== ""){
            dateToUpdate = this.convertDate(date);
        }
        else{
            dateToUpdate = ""
        }
        this.props.handleDate('GeldigTotDatum', this.props.qualification.UUID_Kwalificatie, dateToUpdate);
    }
    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    viewQualification(qualification){
        if(!this.state.isDownloading){
            this.props.history.push({
                pathname: '/viewqualification',
                state: { uuid: qualification.UUID_User_Kwalificatie }
            });
        }
    }

    render(){
        let qualification = this.props.qualification
        let takenboek = false;
        let takenboekCompleted = true;

        if(qualification.User_Onderdeel_Kwalificaties_Takenboeken && qualification.User_Onderdeel_Kwalificaties_Takenboeken.length > 0){
            takenboek = qualification.User_Onderdeel_Kwalificaties_Takenboeken;
            takenboek.forEach((exercise) => {
                if(exercise.Behaald !== 1){
                    takenboekCompleted = false;
                }
            })
        }

        registerLocale('nl', nl);

        return <div className="slim-form-row" key={qualification.UUID_Kwalificatie}>
            <div className="slim-form-row-row">
                <p className="slim-form-row-text">{qualification.Naam}</p>
                {takenboek ?
                    <label className="slim-button-inline" style={{maxWidth: '200px'}} onClick={() => {this.props.toggleTakenboek(qualification)}}><FontAwesomeIcon style={{float: 'left'}} icon={takenboekCompleted ? faClipboardCheck : faClipboard}/>Takenboek</label>
                    : null
                } 
                {this.props.overrideAssessor ?
                    <div className="slim-checkbox-container">
                        <label className="slim-checkbox">
                            <input 
                                className="slim-checkbox-input" 
                                id={qualification.UUID_Kwalificatie} 
                                value={qualification.UUID_Kwalificatie} 
                                type={'checkbox'} 
                                onChange={this.props.handleCheck}
                                checked={this.props.checked}
                            />
                            <span className={`slim-checkbox-override${
                                ((this.props.checked === 1 && this.props.overrideAssessor) || !this.props.overrideAssessor) && qualification.GeldigTotDatum && new Date(qualification.GeldigTotDatum).getTime() - new Date().getTime() < 5184000000 ? ' expiring' : ''
                            }`}/>
                        </label>
                        <label htmlFor={qualification.UUID_Kwalificatie} style={{cursor: 'pointer'}}>In bezit</label>
                    </div> : null
                }
            </div>
            <div 
                className={"slim-form-row-row"} 
                style={{
                    padding: '10px 6px', 
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderTop: '1px solid var(--border)',
                    transition: this.props.checked !== 1 ? 'max-height 0.4s linear, padding 0.1s ease 0.3s, opacity 0.4s ease' : 'max-height 0.4s linear, padding 0.1s ease, opacity 0.4s ease',
                    maxHeight: '500px',
                    opacity: this.props.overrideAssessor && !this.props.checked ? '0.4' : '1',
                    pointerEvents: this.props.overrideAssessor && !this.props.checked ? 'none' : 'all'
                }}
            >
                <div className="slim-form-row-column" style={{marginRight:'10px', alignItems:'flex-start', width:'50%', maxWidth:'200px'}}>
                    {!this.props.selectedUser ? 
                        <>
                            <input type={'file'} accept={'application/pdf'} id={`${qualification.UUID_Kwalificatie}-file`} onChange={this.props.handleFile}/>
                            <label htmlFor={`${qualification.UUID_Kwalificatie}-file`} className="slim-button-inline"><FontAwesomeIcon style={{float: 'left'}} icon={faFilePdf}/>Kwalificatie uploaden</label>
                            <p style={{marginTop:'4px'}}>(Alleen PDF)</p>
                        </> : null
                    }
                    {qualification.BevatDocument === 1 ?
                    <>
                        <button
                            className={`slim-button-inline${this.state.isDownloading ? ' disabled' : ''}`}
                            style={{marginTop:'12px', textDecoration:'none'}}
                            onClick={() => {this.viewQualification(qualification)}}
                        >
                            <FontAwesomeIcon style={{float: 'left'}} icon={faExternalLinkAlt}/>Kwalificatie openen
                        </button>

                    </>
                    : <p style={{marginTop:'12px', textAlign: 'left', color: 'var(--disabled)'}}>Nog geen bestand geüpload.</p>}
                </div>
                <div className="slim-form-row-column" style={{alignItems: 'baseline'}}>
                    <div style={{position:'relative'}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                            <p style={{textAlign:'left'}}>Behaald op</p>
                            {qualification.BehaaldDatum && qualification.BehaaldDatum !== "" ?
                                <button 
                                    className="slim-button-inline" 
                                    style={{height: '19px', width: '19px', padding: '0', fontSize: '10px', borderRadius: '20px'}}
                                    onClick={() => {this.handleStartDateChange("")}}
                                >
                                    <FontAwesomeIcon 
                                        icon={faTrash}
                                    />
                                </button>
                                : null
                            }
                        </div>
                        <DatePicker 
                            className="slim-textinput-inline" 
                            locale="nl" 
                            onFocus={(e) => e.target.readOnly = true}
                            selected={qualification.BehaaldDatum && qualification.BehaaldDatum !== "" ? new Date(qualification.BehaaldDatum) : ""} 
                            showYearDropdown
                            id={`${qualification.UUID_Kwalificatie}-behaald`}
                            dateFormatCalendar="MMMM"
                            dateFormat="dd/MM/yyyy"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                            maxDate={qualification.GeldigTotDatum && qualification.GeldigTotDatum !== "" ? new Date(qualification.GeldigTotDatum) : new Date()}
                            onChange={this.handleStartDateChange}
                            onChangeRaw={this.handleDateChangeRaw}
                            placeholderText="Behaaldatum"
                        />
                        <FontAwesomeIcon 
                            icon={faCalendarAlt}
                            style={{
                                position: 'absolute',
                                right: '6px',
                                bottom: '9px',
                                pointerEvents: 'none',
                                color: 'var(--main-color)'
                            }}
                        />
                    </div>
                    <div style={{position:'relative'}}>
                        <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                        }}>
                            <p style={{textAlign:'left', marginTop:'4px'}}>Verloopt op</p>
                            {qualification.GeldigTotDatum && qualification.GeldigTotDatum !== "" ?
                                <button 
                                    className="slim-button-inline" 
                                    style={{height: '19px', width: '19px', padding: '0', fontSize: '10px', borderRadius: '20px'}}
                                    onClick={() => {this.handleEndDateChange("")}}
                                >
                                    <FontAwesomeIcon 
                                        icon={faTrash}
                                    />
                                </button>
                                : null
                            }
                        </div>
                        <DatePicker 
                            className={`slim-textinput-inline${
                                ((this.props.checked === 1 && this.props.overrideAssessor) || !this.props.overrideAssessor) && qualification.GeldigTotDatum && new Date(qualification.GeldigTotDatum).getTime() - new Date().getTime() < 5184000000 ? ' expiring' : ''
                            }`}
                            locale="nl" 
                            onFocus={(e) => e.target.readOnly = true}
                            selected={qualification.GeldigTotDatum && qualification.GeldigTotDatum !== "" ? new Date(qualification.GeldigTotDatum) : null} 
                            showYearDropdown
                            id={`${qualification.UUID_Kwalificatie}-verloopt`}
                            dateFormatCalendar="MMMM"
                            dateFormat="dd/MM/yyyy"
                            yearDropdownItemNumber={20}
                            scrollableYearDropdown
                            onChange={this.handleEndDateChange}
                            onChangeRaw={this.handleDateChangeRaw}
                            placeholderText="Verloopdatum"
                            minDate={qualification.BehaaldDatum && qualification.BehaaldDatum !== "" ? new Date(qualification.BehaaldDatum) : null}
                        />
                        <FontAwesomeIcon 
                            icon={faCalendarAlt}
                            style={{
                                position: 'absolute',
                                right: '6px',
                                bottom: '9px',
                                pointerEvents: 'none',
                                color: 'var(--main-color)'
                            }}
                        />
                    </div>   
                </div>
            </div>
            {
                ((this.props.checked === 1 && this.props.overrideAssessor) || !this.props.overrideAssessor) && qualification.GeldigTotDatum && new Date(qualification.GeldigTotDatum).getTime() - new Date().getTime() < 5184000000 ?
                <div className="slim-form-row-row">
                    <p style={{color: 'var(--error)'}}>
                        <FontAwesomeIcon id="icon" icon={faClock}/>Let op: Deze kwalificatie verloopt binnen twee maanden!
                    </p>
                </div>
                : null
            }
            <div
                className={
                    this.props.overrideAssessor ?
                    "slim-form-row-row hidden"
                    : "slim-form-row-row"
                }
                style={{
                    padding: '10px 6px',
                    maxHeight: '500px',
                    borderTop: '1px solid var(--border)',
                    transition: this.props.checked !== 1 ? '0.2s ease 0.2s' : '0.4s ease'
                }}
            >
                {takenboekCompleted ?
                    this.props.selectedUser ?
                    <div className="slim-checkbox-container">
                        <label className="slim-checkbox">
                            <input 
                                className="slim-checkbox-input" 
                                id={qualification.UUID_Kwalificatie} 
                                value={qualification.UUID_Kwalificatie} 
                                type={'checkbox'} 
                                onChange={this.props.handleCheck}
                                checked={this.props.checked}
                            />
                            <span className="slim-checkbox-override"/>
                        </label>
                        <label htmlFor={qualification.UUID_Kwalificatie} style={{cursor: 'pointer'}}>Onderdeel goedkeuren</label>
                    </div>
                    : this.props.checked === 1 ?
                        <p style={{color: 'var(--success)'}}>
                            <FontAwesomeIcon id="icon" icon={faGraduationCap}/>Dit onderdeel is goedgekeurd door een beoordelaar.
                        </p>
                        : <p style={{color: 'var(--disabled)'}}>
                            <FontAwesomeIcon id="icon" icon={faClock}/>Wacht op goedkeuring door een beoordelaar.
                        </p>
                    : <p style={{color: 'var(--error)'}}>
                        <FontAwesomeIcon id="icon" icon={faTimes}/>Het takenboek van dit onderdeel is nog niet volledig ingevuld.
                    </p>
                }
            </div>
        </div>
    }
}

export default QualificationInput;