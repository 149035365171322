import React, { Fragment } from "react";
import Select from 'react-select';
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/DeleteAccountCMS.css';
import { GetData, PostData } from '../../../Javascripts/API'
import Header from '../../layouts/headerCMS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserMinus, faCheck, faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

class DeleteAccount extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isAuthenticated: false,
            isDeletingUser: false,
            accounts: {},
            message: '',
            accountUUID: '',
            selectValue: null
        }
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    clearText(){
        setTimeout(function() {
            this.setState({
                message: ''
            })
        }.bind(this), 5000);
    }

    handleDeleteUser = async (event) => {
        event.preventDefault();
        if(this.state.selectValue !== null){
            this.setState({ 
                message: 'Een ogenblik geduld. Het gebruikersaccount wordt gedeactiveerd.',
                isDeletingUser: true 
            });
    
            await PostData('cms/users/delete', {
                UUID_UserAccount: this.state.accountUUID,
            })
            .then((response) => {
                if(response.status === 'success'){
                    this.setState({});
                    let accounts = this.state.accounts;
                    let index = accounts.findIndex(x => x.value === this.state.accountUUID);
                    accounts.splice(index, 1);
                    this.setState({ 
                        accounts: accounts,
                        message: 
                            <Fragment>
                                <FontAwesomeIcon id="icon" style={{color: "green"}} icon={faCheck}/>
                                De gebruiker is succesvol gedeactiveerd.
                            </Fragment>,
                        selectValue: null,
                        isDeletingUser: false
                    }, function() {
                        this.clearText();
                     });
                }
                else{
                    this.setState({ message: <Fragment><FontAwesomeIcon id="icon" style={{color: "red"}} icon={faTimes}/><b>Er is een fout opgetreden:</b><br/>{response.message}</Fragment>})
                }
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    handleChange = (option) => {
        this.setState({accountUUID: option.value})
        this.setState({selectValue: option})
    }

    async componentDidMount() {
        await GetData('cms/users/emails')
        .then(response => {
            if(response.status === "success"){
                this.setState({
                    isAuthenticated: true
                });
                this.setState({
                    accounts: response.accounts
                });
            }
            else{
                this.props.history.push('/');
            }
        })
        .catch((err) => {
            this.props.history.push('/');
        })
    }

    render(){
        let accounts = this.state.accounts;
        return (
            <>
                <Header nav={true} data={this.props.data} userData={this.props.userData} history={this.props.history}/>
                <div className="DeleteAccountContainer slim-container">
                    <h1 className="slim-title">Gebruiker deactiveren</h1>
                    <form onSubmit={this.handleDeleteUser}>
                        <div className="inputBox">
                            <p>Gebruiker<span style={{'color':'red'}}>*</span></p>
                            {this.state.isAuthenticated ?
                                <Select
                                    options={accounts}
                                    onChange={(option) => {this.handleChange(option)}}
                                    value={this.state.selectValue}
                                    placeholder={"Selecteer een gebruiker"}
                                />
                            : <p className="slim-notification">De gebruikers worden opgehaald</p>}
                            <p id="subtext"><FontAwesomeIcon style={{'marginRight': '3px'}} icon={faExclamationCircle}/>Kies hierboven het gebruikersaccount om te deactiveren.<br/>Accounts kunnen <span style={{'textDecoration':'underline'}}>niet</span> opnieuw vanuit dit systeem worden geactiveerd.</p>
                            {this.state.message !== '' ? 
                                <h2 className="slim-notification">{this.state.message}</h2> : null
                            }
                        </div>
                        <button className={this.state.selectValue === null || this.state.isDeletingUser ? "slim-button disabled" : "slim-button"} style={{'marginTop':'12px'}}>
                            <FontAwesomeIcon id="icon" icon={faUserMinus}/>
                            Deactiveren
                        </button>
                    </form>
                </div>
            </>
        );
    }
}
  
export default DeleteAccount;  