import React from "react";
import { NavLink } from "react-router-dom";
import '../../../Stylesheets/Global.css';
import Header from '../../layouts/header';
import { GetData } from '../../../Javascripts/API'
import { logout } from '../../../Javascripts/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faPhone, faSignOutAlt, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

class Dashboard extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isAuthenticated: false
        }
    }

    async componentDidMount() {
        await GetData('auth')
        .then(response => {
            if(response.message === "Authenticated"){
                this.setState({
                    isAuthenticated: true
                })
            }
            else{
                this.props.history.push('/');
            }
        })
        .catch((err) => {
            this.props.history.push('/');
        })
    }

    render(){
        return (
            <>
                <Header nav={true} data={this.props.data} userData={this.props.userData} history={this.props.history} selectedUser={this.props.selectedUser} setSelectedUser={this.props.setSelectedUser}/>
                <div className="slim-background"/>
                <div className="slim-container">
                    <h1 className="slim-title">Dashboard</h1>
                    <p className="slim-subtitle">
                        {
                            new Date().getHours() < 12 ?
                              'Goedemorgen'
                            : new Date().getHours() < 18 ?
                              'Goedemiddag'
                            : 'Goedenavond'
                        }
                        {this.props.userData && this.props.userData.firstName !== undefined ? `, ${this.props.userData.firstName}` : null}
                    </p>
                    <NavLink className="slim-button menu-button" to="/trajecten">
                        <FontAwesomeIcon id="icon" icon={faGraduationCap}></FontAwesomeIcon>Trajecten
                    </NavLink>
                    {this.props.userData.accountType === 'Beoordelaar' ?
                        <NavLink className="slim-button menu-button" to="/selectuser">
                            <FontAwesomeIcon id="icon" icon={faClipboardCheck}></FontAwesomeIcon>Beoordelen
                        </NavLink> : null
                    }
                    <NavLink className="slim-button menu-button" to="/contact">
                        <FontAwesomeIcon id="icon" icon={faPhone}></FontAwesomeIcon>Contact
                    </NavLink>
                    <button className="slim-button menu-button" type="button" onClick={() => logout(this.props.history)}><FontAwesomeIcon id="icon" icon={faSignOutAlt}></FontAwesomeIcon>Uitloggen</button>
                </div>
            </>
        );
    }
}



export default Dashboard;  