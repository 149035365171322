import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { PostDownloadData } from '../../../Javascripts/API'
import '../../../Stylesheets/Global.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

function ViewQualification() {
    const location = useLocation();
    const history = useHistory();

    const [url, setUrl] = useState(false);

    useEffect(() => {
        async function fetchPDF() {
            await PostDownloadData('account/qualifications/download', { qual_uuid: location.state.uuid })
                .then((data) => {
                    console.log(data)
                    if (data) {
                        const dataURL = window.URL.createObjectURL(data);
                        setUrl(dataURL)
                    }
                });
        }

        if (location.state && location.state.uuid) {
            fetchPDF();
        }
    }, [location.state]);

    return (
        <>
            <button className='slim-button-inline' style={{ borderRadius: 0, padding: '16px' }} onClick={history.goBack}><FontAwesomeIcon style={{ marginRight: '6px' }} icon={faArrowLeft} />Terug</button>
            {location.state && location.state.uuid ?
                url ?
                    <>
                        <div style={{ padding: '24px' }}>
                            <p style={{ marginBottom: '12px' }}>Wordt de kwalificatie niet weergegeven? Download de PDF met de onderstaande knop.</p>
                            <a className="slim-button-inline" download="Kwalificatie" href={url}>Kwalificatie downloaden</a>
                        </div>
                        <iframe
                            title="Kwalificatie"
                            src={url}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        />
                    </>
                    : <p style={{ padding: '24px' }}>De PDF wordt opgehaald. Een ogenblik geduld alsjeblieft.</p>
                : <p style={{ padding: '24px' }}>De PDF is niet gevonden.</p>}
        </>
    );

}

export default ViewQualification;