import React from "react";
import TakenboekInput from "./TakenboekInput";
import '../../../Stylesheets/Global.css';

class Takenboek extends React.Component {

    state = { 
        checks: {},
        isValid: false
    }

    handleCheck(checkbox) {
        let checkList = this.state.checks;
        let choice = checkbox.target.checked ? 1 : 0;

        checkList[checkbox.target.value] = choice;
        this.setState({checks: checkList});

        this.props.updateTakenboek(checkbox.target.value, choice);
    }

    async componentDidMount(){
        let takenboek = this.props.data.User_Onderdeel_Kwalificaties_Takenboeken;
        
        if(takenboek.length > 0){
            let checkList = {};
            takenboek.forEach((exercise) => {
                checkList[exercise.UUID_Takenboek] = exercise.Behaald !== 1 ? 0 : 1;
            });
            this.setState({
                checks: checkList
            });
        }
    }

    render() {
        let data = this.props.data;
        let takenboek = this.props.data.User_Onderdeel_Kwalificaties_Takenboeken;

        return (
            <div className="slim-container">
                <div className="slim-form-container">
                    <div style={{width: '100%'}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <p className="slim-subtitle">{`Takenboek - ${data.Naam}`}</p>
                            <div className="slim-form" style={{marginBottom: '18px'}}>
                                {takenboek.length > 0 ?
                                    takenboek.map((exercise) => {
                                        return <TakenboekInput 
                                            exercise={exercise} 
                                            handleCheck={this.handleCheck.bind(this)} 
                                            key={exercise.UUID_Takenboek}
                                        />
                                    }) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <button 
                    className={"slim-button"}
                    onClick={() => {this.props.toggleTakenboek(this.props.data)}}
                >
                    Takenboek sluiten
                </button>
            </div>
        );
    }
}

export default Takenboek;