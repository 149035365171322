import React from "react";
import { NavLink } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/Trajecten.css';

class TrajectSelector extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }

    componentDidMount(){
        let data = this.props.data;
        Object.keys(data).forEach((item) => {
            if(data[item].startDate && data[item].startDate !== ""){
                let dateArr = data[item].startDate.split('/');
                data[item].startDate = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`;
            }
            if(data[item].endDate && data[item].endDate !== ""){
                let dateArr = data[item].endDate.split('/');
                data[item].endDate = `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`;
            }
        });
        this.setState({data: data});
    }

    render(){
        let data = this.state.data;
        if(data.length > 0){
            return <div className="SelectionBox" style={{pointerEvents: this.props.isHidden ? 'none' : 'all'}}>
                {data.map((_, index) => (
                    <NavLink key={index} to={{pathname:`/traject/${data[index].UUID_Traject}`, state:{isFinished: this.props.isFinished, isNew: this.props.isNew ? true : false}}}>
                        <p className="title">{data[index].name}</p>
                        <p style={{margin: '10px 0', fontSize: '11px'}}>{data[index].description}</p>
                        {!this.props.isNew ?
                            <div className="trajectInformation">
                                <div className="dateBox">
                                    {data[index].startDate ?
                                        <p style={{marginBottom:'4px'}}>{`Gestart op: ${data[index].startDate}`}</p>
                                        : null
                                    }
                                    {data[index].endDate ?
                                        <p style={{marginBottom:'4px'}}>{`Afgerond op: ${data[index].endDate}`}</p>
                                        : null
                                    }
                                    {!this.props.isFinished ?
                                        <p style={{marginBottom:'4px'}}>{`Onderdelen voltooid: ${data[index].doneCount} van ${data[index].sectionCount}`}</p>
                                        : null
                                    }
                                    {!this.props.isFinished ?
                                        <p>Startkwalificaties: {(data[index].doneCount < 1 && data[index].progress > 0) || data[index].doneCount > 0 ? 'In bezit' : 'Niet in bezit'}</p>
                                        : null
                                    }
                                </div>
                                <CircularProgressbar                                 
                                    value={data[index].progress !== undefined ? data[index].progress : 100} 
                                    text={`${data[index].progress !== undefined ? data[index].progress : 100}%`} 
                                    styles={buildStyles({
                                        pathColor: 'var(--bg)',
                                        textColor: 'var(--bg)',
                                        trailColor: 'transparent',
                                        backgroundColor: 'var(--main-color)',
                                        textSize: '22px',
                                    })}  
                                />
                            </div>
                            : <div className="trajectInformation">
                                <p style={{padding: '8px'}}>{`Onderdelen: ${data[index].section.length}`}</p>
                            </div>}   
                    </NavLink>
                ))}
            </div>
        }
        else{
            return <></>
        }
    }
}

export default TrajectSelector;