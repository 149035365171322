import React, { Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import HeadShake from 'react-reveal/HeadShake';
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/Login.css';
import Header from '../../layouts/headerCMS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserShield, faLock, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { loginCMS } from '../../../Javascripts/Auth'

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    state = {
        username: this.props.location.username ? this.props.location.username : '',
        password: this.props.location.password ? this.props.location.password : '',
        errormsg: '',
        isLoggingIn: false
    }

    handleChange(event) {
        var target = event.target.id;
        var value = event.target.value;
        if (target === "loginUsername") {
            this.setState({ username: value });
        }
        else if (target === "loginPassword") {
            this.setState({ password: value });
        }
    }

    async handleLogin(event) {
        event.preventDefault();
        if(!this.state.isLoggingIn){
            this.setState({isLoggingIn: true});
            var loginUsername = this.state.username;
            var loginPassword = this.state.password;
    
            await loginCMS(loginUsername, loginPassword, this.props.setUserData, this.props.history)
            .catch(error => {
                this.setState({isLoggingIn: false});
                this.setState({errormsg: error.message});
            });
        }
    }

    render(){
        return (
            <>
                <Header nav={false} data={this.props.data} userData={this.props.userData} history={this.props.history}/>
                <div className="slim-background"/>
                <div className="LoginContainer slim-container">
                    <h1 className="slim-title">Inloggen | CMS</h1>
                    {this.state.isLoggingIn ? <div style={{display: 'flex', justifyContent: 'center', marginTop: '24px'}}>
                        <PuffLoader color={'var(--main-color)'} loading={true} size={50} />
                    </div> : null}
                    {this.state.errormsg !== '' ?
                        <HeadShake><p className="slim-notification slim-error" style={{marginLeft:'auto',marginRight:'auto'}}>
                            <Fragment>
                                <FontAwesomeIcon id="icon" icon={faExclamationCircle}/>
                                {this.state.errormsg}
                            </Fragment>
                        </p></HeadShake> : null
                    }
                    <form onSubmit={this.handleLogin}>
                        <span id="user">
                            <FontAwesomeIcon className="icon" icon={faUserShield}/>
                            <input 
                                type={"text"} 
                                id="loginUsername" 
                                value={this.state.username}
                                onChange={this.handleChange} 
                                placeholder={"Gebruikersnaam"} 
                                required
                                autoComplete="on"
                                autoFocus
                            />
                        </span>
                        <span id="psw">
                            <FontAwesomeIcon className="icon" icon={faLock}/>
                            <input 
                                type={"password"}
                                id="loginPassword" 
                                value={this.state.password}
                                onChange={this.handleChange} 
                                placeholder={"Wachtwoord"} 
                                required
                                autoComplete="on"
                            />
                        </span>
                        <button className={this.state.isLoggingIn ? "slim-button disabled" : "slim-button"} style={{'marginTop':'12px'}}>Inloggen</button>
                    </form>
                    <NavLink to={{
                        pathname:"/",
                        state: {
                            username: this.state.username,
                            password: this.state.password
                        } 
                    }}>
                        <FontAwesomeIcon id="icon" icon={faUser}></FontAwesomeIcon>Inloggen als gebruiker
                    </NavLink>
                </div>            
            </>
        );
    }
}
export default withRouter(Login);  