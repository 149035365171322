import React from "react";
import '../../../Stylesheets/Global.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

class CompetentionInput extends React.Component {
    render(){
        let competention = this.props.competention
        let comp_options = {
            1: '-',
            2: '+/-',
            3: '+'
        };
        return <div className="slim-form-row" key={competention.UUID_Competentie}>
            <div className="slim-form-row-row">
                <p className="slim-form-row-text">{competention.Naam}</p>
                {
                    competention.Url !== '' ?
                    <a href={competention.Url.includes('http') ? competention.Url : `http://${competention.Url}`} target="_blank" rel="noopener noreferrer" className="slim-button-inline" style={{width:'auto', display:'inline-block', textDecoration:'none'}}>
                        <FontAwesomeIcon id="icon" icon={faGlobe}></FontAwesomeIcon>
                        Pagina openen
                    </a> : null
                }
            </div>
            <div className="slim-form-row-row" style={{borderTop: '1px solid var(--border)'}}>
                <p className="slim-form-row-text">{competention.Omschrijving}</p>
                {Object.keys(comp_options).map((option) => {
                    option = parseInt(option);
                    let value = this.props.value !== undefined ? 
                    this.props.value === option ? 
                    true : 
                    false : 
                    false;
                    return <div className="slim-checkbox-container" key={option}>
                        <label className="slim-checkbox adjust-height">
                            <input 
                                className="slim-checkbox-input" 
                                id={competention.UUID_Competentie} 
                                value={`${competention.UUID_Competentie}_${option}`} 
                                type={'checkbox'} 
                                onChange={this.props.handleCompCheck}
                                checked={value}
                            />
                            <span className={`slim-checkbox-override${this.props.value === 1 && value ? ' negative' : this.props.value === 2 && value ? ' average' : this.props.value === 3 && value ? ' positive' : ''}`}>
                                <div 
                                    className="slim-checkbox-placeholder"
                                    style={value && value !== 0 ? {display: 'none'} : null}
                                >
                                    {comp_options[option]}
                                </div>
                            </span>
                        </label>
                    </div>
                })}
            </div>
        </div>
    }
}

export default CompetentionInput;