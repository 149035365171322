import '../../Stylesheets/Global.css';
import '../../Stylesheets/Loading.css';
import RotateLoader from "react-spinners/RotateLoader";

function Loading(props) {

    return <div id="loadingBackground">
        <div className="loadingDiv">
            {props.text ?
                <h1 style={{marginBottom: props.size+20}}>{props.text}</h1> : null
            }
            <RotateLoader color={'var(--main-color)'} loading={true} speedMultiplier={0.6} size={props.size} margin={props.size} />
        </div>
    </div>
}

export default Loading