import React, { Fragment } from "react";
import QualificationInput from './QualificationInput';
import '../../../Stylesheets/Global.css';

class Qualifications extends React.Component {

    state = { 
        qualifications: [],
        checks: {}
    }

    handleCheck(checkbox) {
        let checkList = this.state.checks;
        let choice = checkbox.target.checked ? 1 : 0;

        checkList[checkbox.target.value] = choice;
        this.setState({checks: checkList});
        
        this.validateChecks(checkList);

        this.props.updateCheck(checkbox.target.value, choice, 'qualification', null, 'traject');
    }

    handleFile(event) {
        this.props.updateFile(event.target.id.replace('-file',''), event.target.files[0]);
    }; 

    handleDate(dateType, uuid, date){
        this.props.updateDate(uuid, 'qualification', date, dateType);
    }

    validateChecks(checkList = this.state.checks){
        let valid = true;
        Object.keys(checkList).every((check) => {
            if(!checkList[check]){
                valid = false;
                return false;
            }
            return true;
        });
        if(valid){
            this.props.enableContinue();
        }
        else{
            this.props.disableContinue();
        }
    }

    async componentDidMount(){
        if(!this.props.qualifications.length > 0){
            this.props.enableContinue();
        }
        else{
            let checkList = {};
            Object.keys(this.props.qualifications).forEach((key) => {
                checkList[this.props.qualifications[key].UUID_Kwalificatie] = this.props.qualifications[key].Behaald;
            });
            this.setState({
                checks: checkList,
                qualifications: this.props.qualifications
            });
            this.validateChecks(checkList);
        }
    }

    render() {
        return (
            <div className="slim-container">
                <h2 style={{marginTop: '12px', maxWidth: '90%'}}>{this.props.description}</h2>
                <p className="slim-subtitle"><Fragment>Startkwalificatie<span className="slim-required"/></Fragment></p>
                <div className="slim-form-container">
                    <div style={{width: '100%'}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>   
                            <div className="slim-form">
                            {this.state.qualifications.length > 0 ?
                                this.state.qualifications.map((qualification) => {
                                    return <QualificationInput 
                                        qualification={qualification} 
                                        handleCheck={this.handleCheck.bind(this)}
                                        handleFile={this.handleFile.bind(this)}
                                        handleDate={this.handleDate.bind(this)}
                                        checked={this.state.checks[qualification.UUID_Kwalificatie] !== undefined ? this.state.checks[qualification.UUID_Kwalificatie] : false}
                                        key={qualification.UUID_Kwalificatie}
                                        selectedUser={this.props.selectedUser}
                                        overrideAssessor={true}
                                        history={this.props.history}
                                    />
                                })
                                : <p style={{padding: '10px 6px'}}>Bij dit traject horen geen startkwalificaties.</p>}
                            </div> 
                        </div>
                    </div>
                    {this.state.qualifications.length > 0 && !this.props.continue ?
                        <p style={{marginTop: '18px', maxWidth: '700px'}}><span className="slim-required"/>Je dient alle startkwalificaties behaald te hebben voordat je mag starten met dit traject.</p>
                        : null
                    }
                </div>
            </div>
        );
    }
}

export default Qualifications;