import React from "react";
import '../../../Stylesheets/Global.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

class TakenboekInput extends React.Component {
    render(){
        let exercise = this.props.exercise
        return <div className="slim-form-row" key={exercise.UUID_Takenboek}>
            <div className="slim-form-row-row">
                <p className="slim-form-row-text">{exercise.Naam}</p>
                {
                    exercise.Url !== '' ?
                    <a href={exercise.Url.includes('http') ? exercise.Url : `http://${exercise.Url}`} target="_blank" rel="noopener noreferrer" className="slim-button-inline" style={{width:'auto', display:'inline-block', textDecoration:'none'}}>
                        <FontAwesomeIcon id="icon" icon={faGlobe}></FontAwesomeIcon>
                        Taakomschrijving openen
                    </a> : null
                }
            </div>
            <div className="slim-form-row-row" style={{borderTop: '1px solid var(--border)'}}>
                <p className="slim-form-row-text">{exercise.Omschrijving}</p>
                <div className="slim-checkbox-container">
                    <label className="slim-checkbox">
                        <input 
                            className="slim-checkbox-input" 
                            id={exercise.UUID_Takenboek} 
                            value={exercise.UUID_Takenboek} 
                            type={'checkbox'} 
                            onChange={this.props.handleCheck}
                            checked={exercise.Behaald === 1 ? true : false}
                        />
                        <span className="slim-checkbox-override"/>
                    </label>
                    <label htmlFor={exercise.UUID_Takenboek} style={{cursor: 'pointer'}}>Voltooid</label>
                </div>
            </div>
        </div>
    }
}

export default TakenboekInput;