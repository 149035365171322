import React from "react";
import { NavLink } from "react-router-dom";
import '../../../Stylesheets/Global.css';
import Header from '../../layouts/headerCMS';
import { GetData } from '../../../Javascripts/API'
import { logoutCMS } from '../../../Javascripts/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faUserMinus, faEdit, faSignOutAlt  } from '@fortawesome/free-solid-svg-icons'

class Dashboard extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isAuthenticated: false
        }
    }
    
    async componentDidMount() {
        await GetData('cms/auth')
        .then(response => {
            if(response.message === "Authenticated"){
                this.setState({
                    isAuthenticated: true
                })
            }
            else{
                this.props.history.push('/');
            }
        })
        .catch((err) => {
            this.props.history.push('/');
        })
    }

    render(){
        return (
            <>
                <Header nav={true} data={this.props.data} userData={this.props.userData} history={this.props.history}/>
                <div className="slim-background"/>
                <div className="slim-container">
                    <h1 className="slim-title">Dashboard</h1>
                    <p className="slim-subtitle">
                        {
                            new Date().getHours() < 12 ?
                              'Goedemorgen'
                            : new Date().getHours() < 18 ?
                              'Goedemiddag'
                            : 'Goedenavond'
                        }
                        {this.props.userData && this.props.userData.firstName !== undefined ? `, ${this.props.userData.firstName}` : null}
                    </p>
                    <NavLink className="slim-button menu-button" to="/cms/accounts">
                        <FontAwesomeIcon id="icon" icon={faUserPlus}></FontAwesomeIcon>Gebruiker toevoegen
                    </NavLink>
                    <NavLink className="slim-button menu-button" to="/cms/deleteaccount">
                        <FontAwesomeIcon id="icon" icon={faUserMinus}></FontAwesomeIcon>Gebruiker deactiveren
                    </NavLink>
                    <NavLink className="slim-button menu-button" to="/cms/information">
                        <FontAwesomeIcon id="icon" icon={faEdit}></FontAwesomeIcon>Gegevens beheren
                    </NavLink>
                    <button className="slim-button menu-button" type="button" onClick={() => logoutCMS(this.props.history)}><FontAwesomeIcon id="icon" icon={faSignOutAlt}></FontAwesomeIcon>Uitloggen</button>
                </div>
            </>
        );
    }
}


export default Dashboard;  