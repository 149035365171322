// User components
import Login from "./app/users/Login";
import LoggedOut from "./app/users/LoggedOut";
import Dashboard from "./app/dashboard/Dashboard";
import Trajecten from "./app/dashboard/Trajecten";
import Traject from "./app/traject/Traject";
import Result from "./app/traject/Result";
import SelectUser from "./app/dashboard/SelectUser";
import ViewQualification from "./app/traject/ViewQualification";
import Contact from "./app/dashboard/Contact";


// CMS components
import LoginCMS from "./cms/users/Login";
import LoggedOutCMS from "./cms/users/LoggedOut";
import DashboardCMS from "./cms/dashboard/Dashboard";
import AccountsCMS from "./cms/dashboard/Accounts";
import DeleteAccountCMS from "./cms/dashboard/DeleteAccount";
import InformationCMS from "./cms/dashboard/Information";

import {
  Route,
  withRouter,
  Router
} from "react-router-dom";
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

function Routes(props) {
  return (
    <Router history={history}>
      <Route path="/dashboard" component={() => <Dashboard data={props.data} userData={props.userData} history={history} selectedUser={props.selectedUser} setSelectedUser={props.setSelectedUser} /> } />
      <Route path="/trajecten" component={() => <Trajecten data={props.data} userData={props.userData} history={history} selectedUser={props.selectedUser} setSelectedUser={props.setSelectedUser} /> } />
      <Route path="/traject" component={() => <Traject data={props.data} userData={props.userData} history={history} selectedUser={props.selectedUser} setSelectedUser={props.setSelectedUser} /> } />
      <Route path="/result" component={() => <Result data={props.data} userData={props.userData} history={history} selectedUser={props.selectedUser} setSelectedUser={props.setSelectedUser} /> } />
      <Route path="/selectuser" component={() => <SelectUser data={props.data} userData={props.userData} history={history} selectedUser={props.selectedUser} setSelectedUser={props.setSelectedUser} /> } />
      <Route path="/viewqualification" component={() => <ViewQualification history={history} /> } />
      <Route path="/contact" component={() => <Contact data={props.data} userData={props.userData} history={history} selectedUser={props.selectedUser} setSelectedUser={props.setSelectedUser} /> } />
      <Route path="/loggedout" component={() => <LoggedOut data={props.data} history={history} /> } />
      <Route exact path="/" component={() => <Login data={props.data} setUserData={props.setUserData} history={history} setSelectedUser={props.setSelectedUser} /> } />

      <Route path="/cms/dashboard" component={() => <DashboardCMS data={props.data} userData={props.userData} history={history}/> } />
      <Route path="/cms/loggedout" component={() => <LoggedOutCMS data={props.data} history={history}/> } />
      <Route path="/cms/accounts" component={() => <AccountsCMS data={props.data} userData={props.userData} history={history}/> } />
      <Route path="/cms/deleteaccount" component={() => <DeleteAccountCMS data={props.data} userData={props.userData} history={history}/> } />
      <Route path="/cms/information" component={() => <InformationCMS data={props.data} userData={props.userData} history={history}/> } />
      <Route exact path="/cms" component={() => <LoginCMS data={props.data} setUserData={props.setUserData} history={history}/> } />
    </Router>
  )
}

export default withRouter(Routes)