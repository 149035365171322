import React from "react";
import { NavLink } from "react-router-dom";
import Slide from 'react-reveal/Slide';
import { Squash as Hamburger } from 'hamburger-react'
import '../../Stylesheets/Header.css';
import { logoutCMS } from '../../Javascripts/Auth'
import onClickOutside from "react-onclickoutside";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserPlus, faUserMinus, faEdit, faSignOutAlt, faUserShield, faEnvelope } from '@fortawesome/free-solid-svg-icons'


class Header extends React.Component {

    constructor(props) {
        super(props);
        this.toggleNav = this.toggleNav.bind(this);
    }

    toggleNav = () => {
        this.setState({ displayNav: !this.state.displayNav });
    }
    
    handleClickOutside = evt => {
        if(this.props.nav && this.state.displayNav){
            this.toggleNav();
        }
    };

    state = {
        displayNav: false
    }

    render() {
        return (
            <div className="HeaderContainer">
                <div id="headerBar">
                    <img src={`/Images/${this.props.data.logo}`} alt={`${this.props.data.name} logo`}></img>
                    {this.props.nav ? 
                        <div className="navButtons">
                            <NavLink to="/cms/dashboard" style={{display: 'flex', alignItems: 'center'}}><FontAwesomeIcon id="icon" icon={faHome}></FontAwesomeIcon></NavLink>
                            <Hamburger size={24} rounded color={"var(--main-color)"} id="icon" toggled={this.state.displayNav} toggle={this.toggleNav} />
                        </div> : null   
                    }
                </div>
                <Slide duration={400} top when={this.state.displayNav}>
                <div id="navWindow" className={"noselect"} style={{display: this.state.displayNav ? 'flex' : 'none'}}>
                    {this.props.userData ?
                        <div style={{padding: '16px', fontSize: '12px'}}>
                            <p style={{fontWeight: 'bold'}}>Ingelogd als</p>
                            <p style={{marginTop: '8px'}}>
                                <FontAwesomeIcon id="icon" icon={faUserShield}></FontAwesomeIcon>
                                {this.props.userData.fullName}
                            </p>  
                            <p style={{marginTop: '4px'}}>
                                <FontAwesomeIcon id="icon" icon={faEnvelope}></FontAwesomeIcon>
                                {this.props.userData.email}
                            </p>
                        </div> : null
                    }
                    <hr/>
                    <NavLink to="/cms/dashboard" onClick={this.toggleNav}>
                        <FontAwesomeIcon id="icon" icon={faHome}></FontAwesomeIcon>Dashboard
                    </NavLink>
                    <NavLink to="/cms/accounts" onClick={this.toggleNav}>
                        <FontAwesomeIcon id="icon" icon={faUserPlus}></FontAwesomeIcon>Gebruiker toevoegen
                    </NavLink>
                    <NavLink to="/cms/deleteaccount" onClick={this.toggleNav}>
                        <FontAwesomeIcon id="icon" icon={faUserMinus}></FontAwesomeIcon>Gebruiker deactiveren
                    </NavLink>
                    <NavLink to="/cms/information" onClick={this.toggleNav}>
                        <FontAwesomeIcon id="icon" icon={faEdit}></FontAwesomeIcon>Gegevens beheren
                    </NavLink>
                    <hr/>
                    <button type="button" onClick={() => logoutCMS(this.props.history)}><FontAwesomeIcon id="icon" icon={faSignOutAlt}></FontAwesomeIcon>Uitloggen</button>
                </div>
                </Slide>
            </div>
        );
    }
}


export default onClickOutside(Header);
