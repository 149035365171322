import React, { Fragment } from "react";
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/InformationCMS.css';
import { GetData, PostFormData } from '../../../Javascripts/API'
import Header from '../../layouts/headerCMS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faExclamationCircle, faUpload } from '@fortawesome/free-solid-svg-icons'

class Information extends React.Component {

    constructor(props){
        super(props) 
        this.state = {
            isAuthenticated: false,
            isUpdatingInfo: false,
            message: '',
            formData: {
                name: this.props.data.name,
                email: this.props.data.email,
                phone: this.props.data.phone,
                website: this.props.data.website,
                ontwikkeltrajecten: this.props.data.ontwikkeltrajecten,
                logo: '',
            },
            companyLogo: this.props.data.logo,
            uploadedLogoAsImage: ''
        }
        this.handleUpdateInfo = this.handleUpdateInfo.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleUpdateInfo = async (event) => {
        event.preventDefault();
        if(this.state.isAuthenticated){
            this.setState({
                message: 'Een ogenblik geduld. De gegevens worden aangepast.',
                isUpdatingInfo: true 
            });
    
            const formData = new FormData();
            let data = this.state.formData;
    
            Object.keys(data).forEach((key) => {
                if(!(key === "logo" && data[key].length === 0)){
                    formData.append(key, data[key]);
                }
            })
    
            await PostFormData('cms/settings', formData)
            .then((response) => {
                if(response.status === 'success'){
                    this.props.data.name = data.name;
                    this.props.data.email = data.email;
                    this.props.data.phone = data.phone;
                    this.props.data.website = data.website;
                    this.props.data.ontwikkeltrajecten = data.ontwikkeltrajecten;
                    this.setState({ 
                        message: 
                            <Fragment>
                                <FontAwesomeIcon id="icon" style={{color: "green"}} icon={faCheck}/>
                                De gegevens zijn succesvol aangepast.
                            </Fragment>
                    })
                }
                else{
                    this.setState({ 
                        message: 
                            <Fragment>
                                <FontAwesomeIcon id="icon" style={{color: "red"}} icon={faTimes}/>
                                <b>Er is een fout opgetreden:</b><br/>{response.message}
                            </Fragment>
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    async componentDidMount() {
        await GetData('cms/auth')
        .then(response => {
            if(response.message === "Authenticated"){
                this.setState({
                    isAuthenticated: true
                })
            }
            else{
                this.props.history.push('/');
            }
        })
        .catch((err) => {
            this.props.history.push('/');
        })
    }

    handleChange = (event) => {
        var data = this.state.formData;
        var target = event.target.id;
        var value = event.target.value;

        if(target === "logo"){
            let files = event.target.files;
            data[target] = files[0];
            if (FileReader && files && files.length) {
                var fr = new FileReader();
                fr.onload = function () {
                    this.setState({ uploadedLogoAsImage: fr.result });
                }.bind(this);
                fr.readAsDataURL(files[0]);
            }
        }
        else{
            data[target] = value;
        }
        this.setState({formData: data});
    }

    render(){
        let data = this.state.formData;
        
        return (
            <>
                <Header nav={true} data={this.props.data} userData={this.props.userData} history={this.props.history}/>
                <div className="InformationContainer slim-container">
                    <h1 className="slim-title" style={{marginBottom: '64px'}}>Gegevens beheren</h1>
                    <form onSubmit={this.handleUpdateInfo} encType="multipart/form-data">       
                        <div className="inputBox">
                            <p>Bedrijfsnaam<span style={{'color':'red'}}>*</span></p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="name" 
                                value={data.name}
                                onChange={this.handleChange} 
                                placeholder={"Bedrijfsnaam"} 
                                required
                                autoComplete="off"
                                autoFocus
                            />
                        </div>
                        <div className="inputBox">
                            <p>E-mailadres</p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="email" 
                                value={data.email}
                                onChange={this.handleChange} 
                                placeholder={"E-mailadres"} 
                                autoComplete="off"
                            />
                        </div>
                        <div className="inputBox">
                            <p>Telefoonnummer</p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="phone" 
                                value={data.phone}
                                onChange={this.handleChange} 
                                placeholder={"Telefoonnummer"} 
                                autoComplete="off"
                            />
                        </div>
                        <div className="inputBox">
                            <p>Website URL</p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="website" 
                                value={data.website}
                                onChange={this.handleChange} 
                                placeholder={"https://www.website.nl"} 
                                autoComplete="off"
                            />
                        </div>
                        <div className="inputBox">
                            <p>Ontwikkeltrajecten URL</p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="ontwikkeltrajecten" 
                                value={data.ontwikkeltrajecten}
                                onChange={this.handleChange} 
                                placeholder={"https://www.website/ontwikkeltrajecten.nl"} 
                                autoComplete="off"
                            />
                        </div>
                        <div className="inputBox">
                            <p>Logo</p>
                            <div id="logoUpload">
                                { this.state.uploadedLogoAsImage.length > 0 ? 
                                    <img src={this.state.uploadedLogoAsImage} alt={`${this.props.data.name} logo`}></img> :
                                this.props.data.logo.length > 0 ?
                                    <img src={`/Images/${this.props.data.logo}`} alt={`${this.props.data.name} logo`}></img> : null
                                }
                                <input 
                                    type={"file"} 
                                    id="logo" 
                                    onChange={this.handleChange} 
                                    autoComplete="off"
                                    accept="image/*"
                                />
                            </div>
                            <label htmlFor="logo"><FontAwesomeIcon id="icon" icon={faUpload}/>Logo uploaden</label>
                            <p id="subtext"><FontAwesomeIcon style={{'marginRight': '3px'}} icon={faExclamationCircle}/>Klik na het uploaden op 'Opslaan' om het logo te wijzigen. Het nieuwe logo wordt soms pas zichtbaar na het herladen van de pagina.</p>
                        </div>
                        {this.state.isUpdatingInfo ? 
                            <h2 className="slim-notification">{this.state.message}</h2> : null
                        }
                    <button className="slim-button" style={{'marginTop':'12px'}}>
                        <FontAwesomeIcon id="icon" icon={faCheck}/>
                        Opslaan
                    </button>
                    </form>
                </div>
            </>
        );
    }
}
  
export default Information;  