import React from "react";
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/Result.css';
import Header from '../../layouts/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

class Result extends React.Component {

    constructor(props){
        super(props)
        
        this.state = {
            trajectData: {}
        }
    }

    componentDidMount(){
        if(this.props.history.location.state !== undefined && this.props.userData.status !== 401){
            let traject = this.props.history.location.state.traject;

            if(traject.Behaald === 1){
                let uuid = this.props.history.location.state.uuid;
                if((this.props.selectedUser && uuid === this.props.selectedUser.UUID) || (!this.props.selectedUser && uuid === this.props.userData.UUID)){
                    this.setState({trajectData: traject});
                }
                else{
                    this.props.history.push('/trajecten');
                }
            }
            else{
                this.props.history.push('/trajecten');
            }  
        }
        else{
            this.props.history.push('/trajecten');
        }
    }

    render(){
        if(this.state.trajectData !== undefined && Object.keys(this.state.trajectData).length > 0){
            let trajectData = this.state.trajectData;
            let data = this.props.data;
            return (
                <>
                    <Header nav={true} data={data} userData={this.props.userData} history={this.props.history} selectedUser={this.props.selectedUser} setSelectedUser={this.props.setSelectedUser}/>
                    <div className="slim-container" style={{position: 'relative'}}>
                        <h1 className="slim-title">{`${trajectData.TrajectNaam} - Resultaat`}</h1> 
                        <h2 style={{marginTop:'18px', fontSize:'14px'}}>{`Proficiat, ${this.props.selectedUser ? this.props.selectedUser.firstName : this.props.userData.firstName}`}</h2>
                        <p style={{margin: '16px 0'}}>Je hebt het onderstaande traject succesvol afgerond.</p>

                        <div className="slim-main">
                            <div id="resultContainer" style={{width: '90%'}}>
                                <h2 style={{margin: '0 0 16px', fontSize: '14px', maxWidth: '90%'}}>{trajectData.TrajectNaam} - {trajectData.Omschrijving}</h2>
                                <hr style={{margin: '12px'}}/>
                                <div style={{
                                    width: '90%',
                                    padding: '0 12px',
                                    textAlign: 'left',
                                    boxSizing: 'border-box'
                                }}>
                                    <p className="slim-subtitle" style={{padding: '0 0 18px'}}>Startkwalificaties</p>
                                    {trajectData.Relatie_Template_Ontwikkeltrajecten_Kwalificaties.map((qualification) => {
                                        return <div key={qualification.UUID_Kwalificatie} style={{marginBottom: '6px'}}>
                                            <p>
                                                <FontAwesomeIcon style={{marginRight: '6px', color: 'var(--success)'}} icon={faCheckCircle}/>{qualification.Naam}
                                            </p>
                                        </div>
                                    })}
                                </div>
                                <hr style={{margin: '12px'}}/>
                                <div style={{
                                    width: '90%',
                                    padding: '0 12px',
                                    textAlign: 'left',
                                    boxSizing: 'border-box'
                                }}>
                                    <p className="slim-subtitle" style={{padding: '0 0 18px'}}>Onderdelen</p>
                                    {trajectData.Relatie_Ontwikkeltraject_Onderdelen.map((section) => {
                                        return <div key={section.UUID_Onderdeel} style={{marginBottom: '6px'}}>
                                            <p>
                                                <FontAwesomeIcon style={{marginRight: '6px', color: 'var(--success)'}} icon={faCheckCircle}/>{section.Naam}
                                            </p>
                                        </div>
                                    })}
                                </div>
                                <hr style={{margin: '12px'}}/>
                            </div>
                            <button
                                className={"slim-button"}
                                onClick={() => {this.props.history.push('/trajecten')}}
                            >
                                Terug naar overzicht
                            </button>
                        </div>
                    </div>
                </>
            );
        }
        else{
            return <></>
        }
    }
}
  
export default Result;  