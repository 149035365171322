import React from "react";
import { NavLink } from "react-router-dom";
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/LoggedOut.css';
import Header from '../../layouts/headerCMS';

class LoggedOut extends React.Component {
    render(){
        return (
            <>
                <Header nav={false} data={this.props.data} userData={this.props.userData} history={this.props.history}/>
                <div className="LoggedOutContainer slim-container">
                    <h1 className="slim-title">Uitgelogd</h1>
                    <div>
                        <img src={`/Images/${this.props.data.logo}`} alt={`${this.props.data.name} logo`}></img>
                        <p>Tot ziens</p>
                    </div>
                    <NavLink className="slim-button" to="/">
                        Inloggen
                    </NavLink>
                </div>
            </>
        );
    }
}
  
export default LoggedOut;