import React from "react";
import Fade from 'react-reveal/Fade';
import PuffLoader from "react-spinners/PuffLoader";
import TrajectSelector from "./TrajectSelector";
import { GetData } from '../../../Javascripts/API'
import Header from '../../layouts/header';
import 'react-circular-progressbar/dist/styles.css';
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/Trajecten.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'

class Trajecten extends React.Component {

    constructor(props){
        super(props)
        
        this.state = {
            isAuthenticated: false,
            showCompleted: false,
            data: {}
        }

        this.toggleCompleted = this.toggleCompleted.bind(this);
    }

    async componentDidMount() {
        await GetData(`dashboard${this.props.selectedUser ? `?assessuuid=${this.props.selectedUser.UUID}` : ''}`)
        .then(response => {
            if(response.status === 'success'){
                this.setState({
                    data: response,
                    isAuthenticated: true
                })
                if(!Object.keys(response.new_trajects).length > 0 && !Object.keys(response.existing_trajects).length > 0){
                    this.setState({showCompleted: true});
                }
            }
            else if(response.status === 'none found'){
                let data = {
                    new_trajects: {},
                    existing_trajects: {},
                    finished_trajects: {}
                }
                this.setState({
                    data: data,
                    isAuthenticated: true
                })
            }
            else{
                this.props.history.push('/');
            }
        })
        .catch((err) => {
            this.props.history.push('/');
        })
    }

    toggleCompleted(){
        this.setState({showCompleted: !this.state.showCompleted});
    }

    render(){
        let new_trajects = this.state.data.new_trajects;
        let existing_trajects = this.state.data.existing_trajects;
        let finished_trajects = this.state.data.finished_trajects;

        return (
            <>
                <Header nav={true} data={this.props.data} userData={this.props.userData} history={this.props.history} selectedUser={this.props.selectedUser} setSelectedUser={this.props.setSelectedUser}/>
                <div className="slim-container">
                    <h1 className="slim-title">{`Trajecten${this.props.selectedUser ? ' (Beoordelaarsmodus)' : ''}`}</h1>
                    <p className="slim-subtitle">{`${this.props.selectedUser ? `Trajecten van ${this.props.selectedUser.fullName}` : 'Maak je keuze'}`}</p>
                    {(new_trajects !== undefined || existing_trajects !== undefined || finished_trajects !== undefined) && this.state.isAuthenticated ?
                        !Object.keys(new_trajects).length > 0 && !Object.keys(existing_trajects).length > 0 && !Object.keys(finished_trajects).length > 0 ?
                        <p style={{marginTop:'12px'}}>Er zijn geen trajecten gevonden.</p>
                        : 
                        <>
                            {Object.keys(new_trajects).length > 0 ?
                            <>
                                <h2 style={{marginTop:'12px', fontSize:'15px'}}>Nieuwe trajecten</h2>
                                <Fade duration={300}><TrajectSelector data={new_trajects} isNew={true} isFinished={false}/></Fade>
                            </>
                            : <></>}
                            {Object.keys(existing_trajects).length > 0 ?
                            <>
                                <h2 style={{marginTop:'12px', fontSize:'15px'}}>Lopende trajecten</h2>
                                <Fade duration={300}><TrajectSelector data={existing_trajects} isNew={false} isFinished={false}/></Fade>
                            </>
                            : <></>}
                            {Object.keys(finished_trajects).length > 0 ?
                            <>
                                <h2 style={{marginTop:'12px', fontSize:'15px', cursor:'pointer'}} onClick={() => {this.toggleCompleted()}}>
                                    Voltooide trajecten <FontAwesomeIcon style={{marginLeft: '6px'}} icon={this.state.showCompleted ? faEye : faEyeSlash}/>
                                </h2>
                                <Fade duration={300} when={this.state.showCompleted}><TrajectSelector data={finished_trajects} isNew={false} isFinished={true} isHidden={!this.state.showCompleted}/></Fade>
                            </>
                            : <></>}
                        </>  
                    : <div style={{display: 'flex', marginTop: '24px'}}><PuffLoader color={'var(--main-color)'} loading={true} size={80} /></div>}
                </div>
            </>
        );
    }
}
  
export default Trajecten;  