import React, { Fragment } from "react";
import Takenboek from './Takenboek';
import CompetentionInput from './CompetentionInput';
import QualificationInput from "./QualificationInput";
import '../../../Stylesheets/Global.css';

class Section extends React.Component {

    constructor(props) {
        super(props); 
        this.handleTextUpdate = this.handleTextUpdate.bind(this);
    }

    state = { 
        uuid: '',
        text: '',
        prevText: '',
        qual_checks: {},
        comp_checks: {},
        showTakenboek: false,
        takenboekData: ''
    }

    toggleTakenboek(qualification){
        this.props.toggleButton();
        this.setState({
            takenboekData: qualification,
            showTakenboek: !this.state.showTakenboek
        }, function(){
            this.validateChecks();
        });
    }

    updateTakenboek(UUID_Child, choice){
        this.props.updateCheck(UUID_Child, choice, 'taskbook', this.state.takenboekData.UUID_User_Kwalificatie);
    }

    handleKwalificatieCheck(checkbox) {
        let qualificationCheckList = this.state.qual_checks;
        let choice = checkbox.target.checked ? 1 : 0;

        qualificationCheckList[checkbox.target.value] = choice;
        this.setState({qual_checks: qualificationCheckList});
        
        this.props.updateCheck(checkbox.target.value, choice, 'qualification');

        this.validateChecks(qualificationCheckList, undefined);
    }
    handleCompCheck(checkbox) {
        let competentionCheckList = this.state.comp_checks;
        let UUID_comp = checkbox.target.value.split('_')[0];
        let choice;
        if(checkbox.target.checked){
            choice = parseInt(checkbox.target.value.split('_')[1]);
        }
        else{
            choice = 0;
        }

        competentionCheckList[UUID_comp] = choice;
        this.setState({comp_checks: competentionCheckList});

        this.props.updateCheck(UUID_comp, choice, 'competence');

        this.validateChecks(undefined, competentionCheckList);
    }

    handleFile(event) {
        this.props.updateFile(event.target.id.replace('-file',''), event.target.files[0]);
    }; 

    handleDate(dateType, uuid, date){
        this.props.updateDate(uuid, 'section', date, dateType);
    }

    handleTextUpdate(textbox){
        this.setState({text: textbox.target.value});
        if(this.state.prevText !== this.state.text){
            let UUID = this.props.sectionData.UUID_User_Onderdeel;
            this.props.updateRemarks(UUID, textbox.target.value);
            this.setState({prevText: this.state.text});
        }  
    }
    
    validateChecks(qualificationCheckList = this.state.qual_checks, competentionCheckList = this.state.comp_checks){
        let valid = true;
        Object.keys(qualificationCheckList).every((check) => {
            if(qualificationCheckList[check] !== 1){
                valid = false;
                return false;
            }
            return true;
        });
        Object.keys(competentionCheckList).every((check) => {
            if(competentionCheckList[check] === 0 || competentionCheckList[check] === ''){
                valid = false;
                return false;
            }
            return true;
        });

        this.props.sectionData.User_Data_Onderdelen_Kwalificaties.every((qualification) => {
            if(qualification.User_Onderdeel_Kwalificaties_Takenboeken){
                qualification.User_Onderdeel_Kwalificaties_Takenboeken.every((exercise) => {
                    if(exercise.Behaald !== 1){
                        valid = false;
                        return false;
                    }
                    return true;
                })
            }
            return true;
        })

        if(valid){
            this.props.enableContinue();
        }
        else{
            this.props.disableContinue();
            if(this.props.sectionData.Behaald === 1){
                this.props.updateSection(this.props.sectionData.UUID_Onderdeel, 0);
            }
        }
    }

    async componentDidMount(){
        let data = this.props.sectionData;

        let qualificationCheckList = {};
        let competentionCheckList = {};

        let qual_checks = data.User_Data_Onderdelen_Kwalificaties;
        let comp_checks = data.User_Data_Onderdelen_Competenties;

        qual_checks.forEach((key) => {
            qualificationCheckList[key.UUID_Kwalificatie] = key.Behaald
        });
        comp_checks.forEach((key) => {
            competentionCheckList[key.UUID_Competentie] = key.Waarde;
        });

        this.setState({
            qual_checks: qualificationCheckList,
            comp_checks: competentionCheckList,
            text: data.Opmerkingen,
            prevText: data.Opmerkingen
        });

        this.validateChecks(qualificationCheckList, competentionCheckList);
    }

    render() {
        let data = this.props.sectionData;

        return (
            <div className="slim-container">
                <h2 style={{marginTop: '12px', maxWidth: '90%'}}>{`${data.Omschrijving}`}</h2>
                    {this.state.showTakenboek ?
                        <Takenboek 
                            data={this.state.takenboekData} 
                            updateTakenboek={this.updateTakenboek.bind(this)} 
                            toggleTakenboek={this.toggleTakenboek.bind(this)} 
                            selectedUser={this.props.selectedUser}
                        /> :
                    <div className="slim-form-container">
                        <div style={{width: '100%'}}>
                            {data.Url !== "" ?
                                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p className="slim-subtitle">Informatie onderdeel</p>
                                    <p style={{marginBottom: '8px'}}>Bestudeer de informatie die bij dit onderdeel hoort door op de onderstaande knop te klikken.</p>
                                    <a href={data.Url.includes('http') ? data.Url : `http://${data.Url}`} target="_blank" rel="noopener noreferrer" className="slim-button" style={{margin: '4px 0', padding: '4px 0'}}>
                                        <p>{data.Url.replace(/https:\/\/|http:\/\//gi, "")}</p>
                                    </a>
                                </div>
                            : null }
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <p className="slim-subtitle"><Fragment>Kwalificaties<span className="slim-required"/></Fragment></p>
                                <div className="slim-form">
                                    {data.User_Data_Onderdelen_Kwalificaties.length > 0 ?
                                        data.User_Data_Onderdelen_Kwalificaties.map((qualification) => {
                                            return <QualificationInput 
                                                qualification={qualification}
                                                handleCheck={this.handleKwalificatieCheck.bind(this)}
                                                handleFile={this.handleFile.bind(this)}
                                                handleDate={this.handleDate.bind(this)}
                                                checked={this.state.qual_checks[qualification.UUID_Kwalificatie] !== undefined ? this.state.qual_checks[qualification.UUID_Kwalificatie] : false}
                                                key={qualification.UUID_Kwalificatie}
                                                toggleTakenboek={this.toggleTakenboek.bind(this)}
                                                selectedUser={this.props.selectedUser}
                                                history={this.props.history}
                                            />
                                        })
                                    : <p style={{padding: '10px 6px'}}>Bij dit onderdeel horen geen kwalificaties.</p>}
                                </div>
                            </div>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <p className="slim-subtitle"><Fragment>Competenties<span className="slim-required"/></Fragment></p>
                                <div className="slim-form">
                                {data.User_Data_Onderdelen_Competenties.length > 0 ?
                                    data.User_Data_Onderdelen_Competenties.map((competention) => {
                                        return <CompetentionInput
                                            competention={competention}
                                            value={this.state.comp_checks[competention.UUID_Competentie]}
                                            handleCompCheck={this.handleCompCheck.bind(this)}
                                            key={competention.UUID_Competentie}
                                        />
                                    })
                                    : <p style={{padding: '10px 6px'}}>Bij dit onderdeel horen geen competenties.</p>}
                                </div>
                            </div>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <p className="slim-subtitle">Opmerkingen</p>
                                <textarea 
                                    className="slim-textarea" 
                                    value={this.state.text}
                                    onChange={(textbox) => {this.setState({text: textbox.target.value})}}
                                    onBlur={this.handleTextUpdate}
                                />
                            </div>
                        </div>
                        {!this.props.continue ?
                            <p style={{marginTop: '18px', maxWidth: '700px'}}><span className="slim-required"/>Alle kwalificaties dienen door een beoordelaar goedgekeurd te zijn en alle competenties dienen ingevuld te zijn voordat je verder mag gaan met dit traject.</p>
                            : null
                        }
                    </div>
                }
            </div>
        );
    }
}

export default Section;