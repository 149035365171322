import React, { Fragment } from "react";
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/AccountCMS.css';
import { GetData, PostData } from '../../../Javascripts/API'
import Header from '../../layouts/headerCMS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faCheck, faTimes, faExclamationCircle, faGraduationCap, faUser } from '@fortawesome/free-solid-svg-icons'

class Accounts extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isAuthenticated: false,
            isCreatingUser: false,
            message: '',
            accountEmail: '',
            accountFirstname: '',
            accountSecondname: '',
            accountLastname: '',
            accountType: 'User'
        }
        this.handleCreateUser = this.handleCreateUser.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    clearText(){
        setTimeout(function() {
            this.setState({
                message: ''
            })
        }.bind(this), 5000);
    }

    handleChange = (event) => {
        var target = event.target.id;
        var value = event.target.value;
        switch(target){
            case "newAccountEmail":
                this.setState({ accountEmail: value });
                break;
            case "newAccountFirstname":
                this.setState({ accountFirstname: value });
                break;
            case "newAccountSecondname":
                this.setState({ accountSecondname: value });
                break;
            case "newAccountLastname":
                this.setState({ accountLastname: value });
                break;
            case "newAccountType":
                this.setState({ accountType: value });
                break;
            default: break;
        }
    }

    handleCreateUser = async (event) => {
        event.preventDefault();
        this.setState({ 
            message: 'Een ogenblik geduld. De gebruiker wordt aangemaakt.',
            isCreatingUser: true 
        });
        await PostData('cms/users/create', {
            email: this.state.accountEmail,
            firstname: this.state.accountFirstname,
            secondname: this.state.accountSecondname,
            lastname: this.state.accountLastname,
            type: this.state.accountType
        })
        .then((response) => {
            if(response.status === 'success'){
                this.setState({ 
                    message: 
                        <Fragment>
                            <FontAwesomeIcon id="icon" style={{color: "green"}} icon={faCheck}/>
                            De gebruiker {this.state.accountFirstname} {this.state.accountSecondname} {this.state.accountLastname} is succesvol aangemaakt.
                        </Fragment>,
                    accountEmail: '',
                    accountFirstname: '',
                    accountSecondname: '',
                    accountLastname: '',
                    accountType: 'User',
                    isCreatingUser: false
                }, function() {
                   this.clearText();
                });
            }
            else{
                this.setState({ message: <Fragment><FontAwesomeIcon id="icon" style={{color: "red"}} icon={faTimes}/><b>Er is een fout opgetreden:</b><br/>{response.message}</Fragment>})
            }
        })
        .catch((err) => {
            console.log(err);
        })
    }

    async componentDidMount() {
        await GetData('cms/auth')
        .then(response => {
            if(response.message === "Authenticated"){
                this.setState({
                    isAuthenticated: true
                })
            }
            else{
                this.props.history.push('/');
            }
        })
        .catch((err) => {
            this.props.history.push('/');
        })
    }


    render(){
        return (
            <>
                <Header nav={true} data={this.props.data} userData={this.props.userData} history={this.props.history}/>
                <div className="AccountContainer slim-container">
                    <h1 className="slim-title" style={{marginBottom: '64px'}}>Gebruiker toevoegen</h1>
                    <form onSubmit={this.handleCreateUser}>
                        <div className="inputBox">
                            <p>E-mailadres<span style={{'color':'red'}}>*</span></p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="newAccountEmail" 
                                value={this.state.accountEmail}
                                onChange={this.handleChange} 
                                placeholder={"E-mailadres"} 
                                required
                                autoComplete="off"
                                autoFocus
                            />
                        </div>
                        <div className="inputBox">
                            <p>Voornaam<span style={{'color':'red'}}>*</span></p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="newAccountFirstname" 
                                value={this.state.accountFirstname}
                                onChange={this.handleChange} 
                                placeholder={"Voornaam"} 
                                required
                                autoComplete="off"
                            />
                        </div>
                        <div className="inputBox">
                            <p>Tussenvoegsels</p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="newAccountSecondname" 
                                value={this.state.accountSecondname}
                                onChange={this.handleChange} 
                                placeholder={"Tussenvoegsels"} 
                                autoComplete="off"
                            />
                        </div>
                        <div className="inputBox">
                            <p>Achternaam<span style={{'color':'red'}}>*</span></p>
                            <input 
                                className="slim-textinput"
                                type={"text"} 
                                id="newAccountLastname" 
                                value={this.state.accountLastname}
                                onChange={this.handleChange} 
                                placeholder={"Achternaam"} 
                                required
                                autoComplete="off"
                            />
                        </div>
                        <div className="inputBox">
                            <p>Accounttype</p>
                            <div className="radioBox">
                                <label className={this.state.accountType === "User" || this.state.accountType === "" ? "activeType" : ''} style={{marginRight: '8px'}}>
                                    <input type="radio" id="newAccountType" onChange={this.handleChange} value="User" checked={this.state.accountType === "User" || this.state.accountType === "" ? "checked" : ''} name="account" />
                                    <FontAwesomeIcon id="icon" icon={faUser}/>Medewerker
                                </label>
                                <label className={this.state.accountType === "Beoordelaar" ? "activeType" : ''}>
                                    <input type="radio" id="newAccountType" onChange={this.handleChange} value="Beoordelaar" checked={this.state.accountType === "Beoordelaar" ? "checked" : ''} name="account" />
                                    <FontAwesomeIcon id="icon" icon={faGraduationCap}/>Beoordelaar
                                </label>
                            </div>
                        </div>
                        
                        <p id="subtext"><FontAwesomeIcon style={{'marginRight': '3px'}} icon={faExclamationCircle}/>De gebruiker ontvangt een e-mail met daarin zijn/haar wachtwoord.<br/>Het ingevoerde e-mailadres wordt automatisch de gebruikersnaam.</p>
                        {this.state.message !== '' ? 
                            <h2 className="slim-notification">{this.state.message}</h2> : null
                        }
                        <button className={this.state.isCreatingUser ? "slim-button disabled" : "slim-button"} style={{'marginTop':'12px'}}>
                            <FontAwesomeIcon id="icon" icon={faUserPlus}/>
                            {this.state.accountType === "User" ? "Medewerker toevoegen" : "Beoordelaar toevoegen"}
                        </button>
                    </form>
                </div>
            </>
        );
    }
}
  
export default Accounts;  