import React, { Fragment } from "react";
import Fade from 'react-reveal/Fade';
import '../../../Stylesheets/Global.css';
import '../../../Stylesheets/Traject.css';
import ScaleLoader from "react-spinners/ScaleLoader";
import Loading from '../../layouts/Loading';
import { GetData, PostData, PostFormData } from '../../../Javascripts/API'
import Header from '../../layouts/header';
import Qualifications from './Qualifications';
import Section from './Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons'

class Traject extends React.Component {

    constructor(props){
        super(props)

        this.navigate = this.navigate.bind(this);
        
        this.state = {
            isAuthenticated: false,
            trajectData: '',
            qualData: '',
            qualificationList: '',
            sectionData: '',
            navigation: '',
            continue: false,
            currentStep: 'start',
            percentageDone: 0,
            hideButton: false,
            loading: false,
            isUpdating: true,
            init: true,
        }
    }

    navigate(id) {
        
        if(this.state.init){
            this.setState({init: false})
        }
        if(this.state.hideButton){
            this.setState({hideButton: false});
        }
        
        let navigation = this.state.navigation;
        if(navigation.get(id)){
            if(id !== 'qualifications' && id !== 'end'){
                if(this.state.sectionData[id] && !this.state.sectionData[id].UUID_UserAccount){
                    this.fetchSection(id, false, true);
                }
                else{
                    this.setState({
                        currentStep: id,
                        loading: false,
                        isUpdating: false
                    });
                }
            }
            else{
                this.setState({
                    currentStep: id,
                    loading: false,
                    isUpdating: false
                });
            }
        }
    }

    proceed(step){
        let navigation = this.state.navigation;
        navigation.set(step.toString(), true);
        this.setState({
            navigation: navigation,
            currentStep: step,
            loading: false,
            isUpdating: false
        }, function() {
            this.calculatePercentage();
        });
        window.scrollTo(0, 0);
    }

    async nextPage(){
        let currentStep = this.state.currentStep;
        let newStep;

        if(this.state.continue || currentStep === 'qualifications'){
            this.disableContinue();
            if(currentStep === 'qualifications'){
                if(Object.keys(this.state.sectionData).length === 0){
                    newStep = 'end';
                }
                else{
                    newStep = 0;
                }
            }
            else{
                if(parseInt(currentStep)+1 >= Object.keys(this.state.sectionData).length){
                    newStep = 'end';
                }
                else{
                    newStep = parseInt(currentStep)+1;
                }
            }

            let sectionData = this.state.sectionData;
            if(currentStep !== 'qualifications'){
                this.updateSection(sectionData[currentStep].UUID_Onderdeel, 1, currentStep);
            }

            if(newStep !== 'qualifications' && newStep !== 'end'){
                if(sectionData[newStep].UUID_User_Onderdeel === undefined){
                    this.fetchSection(newStep, true);
                }
                else{
                    this.proceed(newStep);
                }
            }
            else{
                this.proceed(newStep);
            }
        }
    }

    enableContinue() {
        this.setState({continue: true}, function() {
            this.calculatePercentage();
        });
    }
    disableContinue() {
        this.setState({continue: false}, function() {
            this.calculatePercentage();
        });
    }

    async updateSection(uuid, value, step = this.state.currentStep){
        await PostData('section/finish/', {uuid, value, assessuuid: this.props.selectedUser.UUID})
        .then((data) => {
            if(data.status === 'success'){
                let sectionData = this.state.sectionData;
                sectionData[step].Behaald = value;
                this.setState({sectionData: sectionData}, function() {
                    this.calculatePercentage();
                });
            }
        });
    }

    async finishTraject(post = true){
        let trajectData = this.state.trajectData;
        if(post){
            await PostData('traject/finish/', {uuid: trajectData.UUID_Ontwikkeltraject, assessuuid: this.props.selectedUser.UUID, value: 1})
            .then((data) => {
                if(data.status === 'success'){
                    trajectData.Behaald = 1;
                    this.setState({trajectData: trajectData}, function() {
                        this.props.history.push('/result', {traject: trajectData, uuid: this.props.selectedUser ? this.props.selectedUser.UUID : this.props.userData.UUID});
                    });
                }
            });
        }
        else{
            trajectData.Behaald = 1;
            this.setState({trajectData: trajectData}, function() {
                this.props.history.push('/result', {traject: trajectData, uuid: this.props.selectedUser ? this.props.selectedUser.UUID : this.props.userData.UUID});
            });
        }
    }

    async fetchSection(step, proceed = false, navigate = false){
        this.setState({
            loading: true,
            isUpdating: true
        });
        let sectionData = this.state.sectionData;
        await PostData('section/', {uuid: sectionData[step].UUID_Onderdeel, assessuuid: this.props.selectedUser.UUID})
        .then(async (data) => {
            if(data.status && data.status === 'success'){
                await this.getQualifications()
                let response_qualifications = data.Section[0].User_Data_Onderdelen_Kwalificaties;
                if(response_qualifications.length > 0){
                    let qualificationList = this.state.qualificationList;

                    Object.keys(response_qualifications).forEach((key) => {
                        if(qualificationList[response_qualifications[key].UUID_Kwalificatie] !== undefined){
                            response_qualifications[key] = qualificationList[response_qualifications[key].UUID_Kwalificatie]
                        }
                    })
                    data.Section[0].User_Data_Onderdelen_Kwalificaties = response_qualifications;
                }

                sectionData[step] = data.Section[0];

                this.setState({
                    sectionData: sectionData
                });

                if(proceed){
                    this.proceed(step);
                }
                else if(navigate){
                    this.navigate(step);
                }
            }
            else{
                this.enableContinue();
            }
        })
        .catch((err) => {
            console.log(err);
            this.enableContinue();
        })
    }

    async updateCheck(UUID_Child, state, type, UUID_Parent = null, route = 'section'){
        this.setState({init: false});
        this.setState({isUpdating: true});
        console.log(`${route}/${type}`)
        await PostData(`${route}/${type}`, {uuid: UUID_Child, assessuuid: this.props.selectedUser.UUID, value: state})
        .then((data) => {
            if(data.updated && data.updated.value === state){
                let sectionData = this.state.sectionData;

                if(type === 'qualification'){
                    if(this.state.currentStep === 'qualifications'){
                        let qualData = this.state.qualData;
                        let index = qualData.findIndex(x => {
                            return x.UUID_Kwalificatie === UUID_Child;
                        });

                        qualData[index].Behaald = state;
                        this.setState({qualData: qualData});
                    }
                    else{
                        let qualObject = sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties;
                        let index = qualObject.findIndex(x => {
                            return x.UUID_Kwalificatie === UUID_Child;
                        });
                        
                        sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties[index].Behaald = state;
                        this.setState({sectionData: sectionData});
                    }
                }
                else if(type === 'competence'){
                    let compObject = sectionData[this.state.currentStep].User_Data_Onderdelen_Competenties;
                    let index = compObject.findIndex(x => {
                        return x.UUID_Competentie === UUID_Child;
                    });

                    sectionData[this.state.currentStep].User_Data_Onderdelen_Competenties[index].Waarde = state;
                    this.setState({sectionData: sectionData});
                }
                else if(type === 'taskbook'){
                    let qualObject = sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties;
                    let index = qualObject.findIndex(x => {
                        return x.UUID_User_Kwalificatie === UUID_Parent;
                    });

                    let qualification = sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties[index];
                    let taskBookIndex = qualification.User_Onderdeel_Kwalificaties_Takenboeken.findIndex(x => {
                        return x.UUID_Takenboek === UUID_Child;
                    })

                    qualification.User_Onderdeel_Kwalificaties_Takenboeken[taskBookIndex].Behaald = state;
                    sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties[index] = qualification;
                    this.setState({sectionData: sectionData});
                }
                this.setState({isUpdating: false});
                this.calculatePercentage(); 
            }
        })
    }

    async updateFile(UUID_Child, file){
        this.setState({isUpdating: true});
        const formData = new FormData();
        formData.append('qualification', file);
        formData.append('uuid', UUID_Child);

        await PostFormData('account/qualifications/upload', formData)
        .then((data) => {
            if(data.message === 'File uploaded'){
                let sectionData = this.state.sectionData;

                if(this.state.currentStep === 'qualifications'){
                    let qualData = this.state.qualData;
                    let index = qualData.findIndex(x => {
                        return x.UUID_Kwalificatie === UUID_Child;
                    });
        
                    let qualificationData = qualData[index];
                    qualificationData.BehaaldDatum = data.response.BehaaldDatum;
                    qualificationData.BevatDocument = data.response.BevatDocument;
                    qualificationData.Kwalificatie = data.response.Kwalificatie;
                    qualificationData.KwalificatieDocumentUrl = data.response.KwalificatieDocumentUrl;

                    qualData[index] = qualificationData
                    this.setState({qualData: qualData});
                }
                else{
                    let qualObject = sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties;
                    let index = qualObject.findIndex(x => {
                        return x.UUID_Kwalificatie === UUID_Child;
                    });
                    let qualificationData = sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties[index];
                    qualificationData.BehaaldDatum = data.response.BehaaldDatum;
                    qualificationData.Kwalificatie = data.response.Kwalificatie;
                    qualificationData.KwalificatieDocumentUrl = data.response.KwalificatieDocumentUrl;

                    sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties[index] = qualificationData;
                    this.setState({sectionData: sectionData});
                }
            }
            this.setState({isUpdating: false});
        });
    }

    async updateRemarks(UUID_Child, text){
        let step = this.state.currentStep;
        this.setState({isUpdating: true});
        await PostData(`section/remarks`, {uuid: UUID_Child, remarks: text})
        .then((data) => {
            let sectionData = this.state.sectionData;
            sectionData[step].Opmerkingen = text;
            this.setState({isUpdating: false});
        });
    }   

    async updateDate(UUID_Child, type, date, dateType){
        this.setState({isUpdating: true});
        await PostData(`account/qualifications/dates`, {uuid: UUID_Child, assessuuid: this.props.selectedUser.UUID, type: dateType, date: date})
        .then(() => {
            if(type === 'qualification'){
                let qualData = this.state.qualData;
                let index = qualData.findIndex(x => {
                    return x.UUID_Kwalificatie === UUID_Child;
                });
    
                qualData[index][dateType] = date;
                this.setState({qualData: qualData});
            }
            else{
                let sectionData = this.state.sectionData;
                let qualObject = sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties;
                let index = qualObject.findIndex(x => {
                    return x.UUID_Kwalificatie === UUID_Child;
                });
                
                sectionData[this.state.currentStep].User_Data_Onderdelen_Kwalificaties[index][dateType] = date;
                this.setState({sectionData: sectionData});
            }
            this.setState({isUpdating: false});
        });
    }

    calculatePercentage(navigation = this.state.navigation){

        let valid = true;
        let destination = false;
        let previousWasTrue = true;

        for (let [key,] of navigation) {
            let entry = this.state.sectionData[key];

            if(key === this.state.currentStep.toString()){
                if(!this.state.continue){
                    previousWasTrue = false;
                }
                navigation.set(key, true);

                if(entry && entry.Behaald !== 1){
                    previousWasTrue = false;
                    valid = false;
                }
            }
            else if(entry){
                if(entry.Behaald === 1 && previousWasTrue){
                    navigation.set(key, true);
                    destination = key;
                }
                else if(entry.Behaald !== 1 && previousWasTrue){
                    valid = false;
                    navigation.set(key, true);
                    destination = key;
                    previousWasTrue = false;
                }
                else{
                    valid = false;
                    navigation.set(key, false);
                }
            }
            else{
                navigation.set(key, false);
            }
        }

        if(!this.state.continue){
            previousWasTrue = false;
        }

        navigation.set('qualifications', true);

        if(valid){
            navigation.set('end', true);
            destination = 'end';
        }
        else{
            navigation.set('end', false);
        }

        let doneArray = Array.from(new Map(navigation).values());
        let percentage = Math.round((doneArray.filter(Boolean).length / (doneArray.length-1)) * 100);
        percentage -= Math.round((100 / (doneArray.length-1)));

        this.setState({
            percentageDone: percentage,
            navigation: navigation
        }, function() {
            if(destination && this.state.init && !this.props.isNew && !this.props.selectedUser){
                this.navigate(destination);
            }
        });
    }

    async getQualifications(qualData = this.state.qualData, navigation = this.state.navigation){
        await GetData(`account/qualifications${this.props.selectedUser ? `?assessuuid=${this.props.selectedUser.UUID}` : ''}`)
        .then(response => {
            let qualifications = {}
            response.forEach((key) => {
                qualifications[key.UUID_Kwalificatie] = key;
                
            });

            this.setState({qualificationList: qualifications});

            if(qualData.length > 0){               
                qualData.forEach((value) => {
                    let index = qualData.findIndex(x => {
                        return x.UUID_Kwalificatie === value.UUID_Kwalificatie;
                    });
                    if(qualifications[value.UUID_Kwalificatie]){       
                        qualData[index] = qualifications[value.UUID_Kwalificatie];
                    }
                });
                let step = this.state.currentStep;
                if(step === 'start'){
                    step = 'qualifications'
                }
                this.setState({
                    qualData: qualData,
                    navigation: navigation,
                    currentStep: step,
                    isUpdating: false
                }, () => {
                    if(this.props.history.location.state.isFinished){
                        this.finishTraject(false);
                    }
                    else{
                        this.calculatePercentage(navigation);
                    }
                });
            }
        });
    }

    async componentDidMount() {
        if(this.props.history.location.state && this.props.history.location.state.isFinished !== undefined){
            let path = window.location.pathname.split('/')
            let UUID_Traject = path[path.length-1];
            if(UUID_Traject.length > 0 && UUID_Traject !== 'traject'){
                let qualData = {};
                let navigation = new Map();
    
                await GetData(`traject/${UUID_Traject}`)
                .then(response => {
                    console.log(response)
                    if(response.status === 'success'){
                        if(response.traject.length < 1){
                            this.props.history.push('/trajecten');
                        }
                        else{
                            this.setState({
                                trajectData: response.traject,
                                sectionData: response.traject.Relatie_Ontwikkeltraject_Onderdelen,
                                isAuthenticated: true
                            });
        
                            navigation.set('qualifications', true);
                            if(response.traject.Relatie_Ontwikkeltraject_Onderdelen){
                                Object.keys(response.traject.Relatie_Ontwikkeltraject_Onderdelen).forEach((key) => {
                                    navigation.set(key, false);
                                });
                            }
                            navigation.set('end', false);
                            if(response.traject.Relatie_Template_Ontwikkeltrajecten_Kwalificaties){
                                qualData = Object.values(response.traject.Relatie_Template_Ontwikkeltrajecten_Kwalificaties);
                            }
                        }
                    }
                    else{
                        this.props.history.push('/');
                    }
                })
                .catch((err) => {
                    // this.props.history.push('/');
                    console.log(err);
                })
                await this.getQualifications(qualData, navigation)
                .then(() => {
                    // this.setState({currentStep: 'qualifications'});
                })
            }
            else{
                this.props.history.push('/');
            }
        }
    }

    render(){
        if(!this.props.history.location.state || this.props.history.location.state.isFinished === undefined){
            this.props.history.push('/trajecten');
            return <></>
        }
        else{
            let trajectData = this.state.trajectData;
            let qualData = this.state.qualData;
            let sectionData = this.state.sectionData;

            let data = this.props.data;

            return (
                <>
                    <Header nav={true} data={data} userData={this.props.userData} history={this.props.history} selectedUser={this.props.selectedUser} setSelectedUser={this.props.setSelectedUser}/>
                    <div className="slim-container" style={{position: 'relative'}}>
                        <h1 className="slim-title" style={{position: 'relative'}}>
                            {this.state.isUpdating ? <div id={'titleLoader'}><ScaleLoader color={'white'} loading={true} height={12} width={2} margin={1} /></div> : null}
                            {trajectData && !this.state.loading ? 
                                `${trajectData.TrajectNaam} ${
                                    this.state.currentStep !== 'start' ?
                                        this.state.currentStep === 'qualifications' ?
                                        ` - Startkwalificatie` :
                                        this.state.currentStep === 'end' ?
                                        ` - Afronding` :
                                        ` - ${sectionData[this.state.currentStep].Naam}` :
                                    ''}`
                                : 'Bezig met laden'} {this.props.selectedUser ? ' (Beoordelaarsmodus)' : ''}</h1>
                        {!this.state.hideButton ?
                            <div className="slim-progress-bar">
                                {this.state.currentStep !== 'start' ?
                                    <>
                                        {
                                            Array.from(new Map(this.state.navigation).keys()).map((key) => {
                                            let navigation = new Map(this.state.navigation);
                                            return <div
                                                key={key} 
                                                className="slim-progress-item"
                                                onClick={() => navigation.get(key) ? sectionData[key] && sectionData[key].UUID_User_Onderdeel === undefined ? this.fetchSection(key, false, true) : this.navigate(key) : null}
                                                style={
                                                    !navigation.get(key) ? {opacity:'0.1', cursor: 'default'} : this.state.currentStep.toString() !== key ? {opacity: '0.6'} : null
                                                }
                                            />
                                            })
                                        }
                                        <div className="slim-progress-item percentageCounter">
                                            {`${this.state.percentageDone}%`}
                                        </div>
                                    </>
                                : !this.props.history.location.state.isFinished ? <div className="slim-progress-item" style={{opacity: '0.1'}}/> : null
                                }
                            </div>
                        : null }
                        {this.state.trajectData !== '' && this.state.currentStep !== 'start' && !this.state.loading ?
                            <>
                            <div className="slim-main">
                                {
                                this.state.currentStep === 'end' ? 
                                    <>
                                        <h2 style={{marginTop: '12px', fontSize: '14px'}}>Einde van het traject</h2>
                                        <p>Controleer of alle onderdelen compleet zijn ingevuld en klik vervolgens op "Afronden" om dit traject te voltooien.</p> 
                                    </> :
                                this.state.currentStep === 'qualifications' ? 
                                <Fade duration={300}><Qualifications 
                                    key={this.state.currentStep}
                                    enableContinue={this.enableContinue.bind(this)} 
                                    disableContinue={this.disableContinue.bind(this)}
                                    updateCheck={this.updateCheck.bind(this)}
                                    updateFile={this.updateFile.bind(this)}
                                    updateDate={this.updateDate.bind(this)}
                                    toggleButton={() => {this.setState({hideButton: !this.state.hideButton})}}
                                    qualifications={qualData}
                                    description={trajectData.Omschrijving}
                                    selectedUser={this.props.selectedUser}
                                    continue={this.state.continue}
                                    history={this.props.history}
                                /></Fade> : 
                                <Fade duration={300}><Section 
                                    key={this.state.currentStep}
                                    enableContinue={this.enableContinue.bind(this)} 
                                    disableContinue={this.disableContinue.bind(this)}
                                    updateCheck={this.updateCheck.bind(this)}
                                    updateFile={this.updateFile.bind(this)}
                                    updateDate={this.updateDate.bind(this)}
                                    updateRemarks={this.updateRemarks.bind(this)}
                                    updateSection={this.updateSection.bind(this)}
                                    toggleButton={() => {this.setState({hideButton: !this.state.hideButton})}}
                                    sectionData={sectionData[this.state.currentStep]}
                                    selectedUser={this.props.selectedUser}
                                    continue={this.state.continue}
                                    history={this.props.history}
                                /></Fade>
                                }
                                {this.state.currentStep !== 'end' ?
                                    !this.state.hideButton ?
                                        <button 
                                            // className={this.state.continue && !this.state.isUpdating ? "slim-button" : "slim-button disabled"}
                                            className={(this.state.continue || this.state.currentStep === 'qualifications') && !this.state.isUpdating ? "slim-button" : "slim-button disabled"}
                                            onClick={() => this.nextPage()}
                                            style={{margin: '32px 0'}}
                                        >
                                            <Fragment>
                                                Volgende
                                                <FontAwesomeIcon style={{marginLeft: '6px'}} icon={faArrowRight}/>
                                            </Fragment>
                                        </button> :
                                        null :
                                    <button 
                                        className={this.state.percentageDone === 100 ? "slim-button" : "slim-button disabled"}
                                        onClick={() => this.finishTraject()}
                                    >
                                        <Fragment>
                                            <FontAwesomeIcon id="icon" icon={faCheck}/>
                                            Traject afronden
                                        </Fragment>
                                    </button>
                                }
                            </div>
                            </>
                        : <>
                            <div className="slim-main">
                                <div/>
                                <button className={"slim-button disabled"} style={{margin: '32px 0'}}>
                                    <Fragment>
                                        Volgende
                                        <FontAwesomeIcon style={{marginLeft: '6px'}} icon={faArrowRight}/>
                                    </Fragment>
                                </button>
                            </div>
                            <Loading/>
                        </>}
                    </div>
                </>
            );
        }
    }
}
  
export default Traject;  